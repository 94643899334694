import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { googleLogo } from "../assets/images";
import { useHistory, Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import { doSignInWithGoogle, FirebaseContext } from "../firebase";
import AuthContext from "./AuthContext";
function Login() {
  const history = useHistory();
  const { setIsSigningIn, isSigningIn } = useContext(AuthContext);

  const handleSignInWithGoogle = () => {
    setIsSigningIn(true);
    doSignInWithGoogle()
      .then(async (user) => {
        // console.log("user: ", user);
      })
      .catch((error) => {
        setIsSigningIn(false);
        toast.error(`Couldn't sign in - ${error.message ? error.message : ""}`);
        console.log(error);
      });
  };

  //   if (currentUser && currentUser.onboarding_complete) {
  //     return <Redirect to="/home" />;
  //   } else if (currentUser) {
  //     return <Redirect to={`/welcome/${currentUser.onboarding_step}`} />;
  //   }

  return (
    <div className="h-screen w-screen flex items-center justify-center px-5 bg-black-333 ">
      <div className="flex bg-white pt-12 pb-6 px-8 sm:pt-12 sm:px-12 sm:pb-6 rounded-md">
        <div className="w-64">
          <div className="justify-center flex flex-col items-center">
            <div className="text-2xl font-semibold">Hey there!</div>
            <div className="text-xl font-normal">Login to Games @ StudyOwl</div>
          </div>
          <div className="mt-3">
            <button
              className="flex items-center justify-center px-5 rounded-md bg-black-111 text-white py-2 w-full"
              onClick={handleSignInWithGoogle}
              disabled={isSigningIn}
            >
              <div>
                {isSigningIn ? (
                  <div className="spinner-grow w-6 h-6 mr-3"></div>
                ) : (
                  <img src={googleLogo} alt="logo" className="h-4 w-4 mx-3" />
                )}
              </div>
              <div>{isSigningIn ? "Loading..." : "Continue with Google"}</div>
            </button>
          </div>

          <div className="flex justify-center mt-6">
            <div className="text-sm">Powered by StudyOwl</div>
          </div>
          <div class="mt-2">
            <div class="font-medium">Instructions to login - </div>
            <ul class="list-disc">
              <li class="text-xs mt-2 leading-none">
                Make sure you're using the college email address for access (only Gmail or Gsuite accounts are valid)
              </li>
              <li class="text-xs mt-2 leading-none">
                If you're facing any problems in logging into to the platform?,
                Please contact your placement department
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
