import storage from "local-storage";
import "rc-tooltip/assets/bootstrap.css";
import React, { useEffect, useRef, useState } from "react";
import Countdown from "react-countdown";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import { BackIcon } from "../../assets/icons";
import { taskImages } from "../../assets/images";
import Dots from "./dots";

let timeoutIndex;
const levelStats = [];
let difficulty;
let dotScore = 0;
let taskScore = 0;
let highScoreColor = {
  easy: "bg-orange-600",
  medium: "bg-green-400",
  hard: "bg-blue-500",
};
let step = 0;
let level = 3;
function GridChallenge() {
  const history = useHistory();
  const [record] = useState(storage("studyowl-grid"));
  const [dots, setDots] = useState([]);
  const [startAt, setStartAt] = useState(Date.now());
  const [waitTime, setWaitTime] = useState(2000);
  const [dotSize, setDotSize] = useState(35);
  const [numberOfDots, setNumberOfDots] = useState(14);
  const [dotOrderSeq, setDotOrderSeq] = useState([]);
  const [dotAnswerSeq, setDotAnswerSeq] = useState([]);
  const [endTime, setEndTime] = useState(Date.now() + 180000);
  const [gameStarted, setGameStarted] = useState(false);
  const [selected, setSelected] = useState(-1);
  const [validaingAnswer, setValidatingAnswer] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState({});
  const [game_box, setGameBox] = useState({});

  // const [timeoutIndex, setTimeoutIndex] = useState(0);
  const [currentTask, setCurrentTask] = useState({});
  const [tasks, setTasks] = useState(
    Object.keys(taskImages).reduce((prev, cur) => {
      return {
        ...prev,
        [cur]: taskImages[cur].map((el) => {
          return { ...el };
        }),
      };
    }, {})
  );
  const [gameOver, setGameOver] = useState(false);
  const countdown = useRef();
  // const validateAnswerAndShowNext = async (ans) => {
  //   setSelected(ans);
  //   if (ans === a[currentQuestion].answer) {
  //     setScore((score) => score + 1);
  //   }
  //   await setTimeout(() => {
  //     setSelected(-1);
  //     setCurrentQuestion((i) => i + 1);
  //   }, 1000);
  // };
  // const startGame = () => {
  //   setScore(0);
  //   setSelected(-1);
  //   setCurrentQuestion(0);
  //   setGameStarted(true);
  //   setEndTime(Date.now() + 180000);
  // };
  const random = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };
  const dist = (x1, y1, x2, y2) => {
    return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
  };

  const endLevel = () => {
    if (level < 5) {
      let currentLevel = level;
      level++;
      swal(`Loading Level: ${currentLevel - 1}`, {
        buttons: false,
        timer: 1500,
      });
      setTimeout(() => {
        startGame();
      }, 2000);
    } else {
      // clearTimeout(timeoutIndex);
      storage(
        "studyowl-grid",
        record
          ? { ...record, [difficulty]: { levelStats, dotScore, taskScore } }
          : { [difficulty]: { levelStats, dotScore, taskScore } }
      );
      swal({
        icon: "success",
        title: "Game Over",
        text: [
          ...levelStats.map((stat) => `Level ${stat.level} = ${stat.outcome}`),
          `Dot Score = ${dotScore}/12`,
          `Subtask Score = ${taskScore}/12`,
        ].join("\n"),
        closeOnClickOutside: false,
        closeOnEsc: false,
        button: {
          text: "Restart",
          closeModal: false,
        },
      }).then((value) => {
        // console.log("value", value);
        window.location.reload();
      });
      setGameOver(true);
    }
  };
  const continueToDotScreen = (dots) => {
    // console.log("dots", dots);
    let currentStep = step;
    // console.log("currentStep", currentStep, step);
    step++;

    if (currentStep + 1 < level * 2) showDotsScreen(dots);
    else {
      setStartAt(Date.now());

      countdown.current.start();

      let waitTime = 12000;
      setWaitTime(waitTime);

      timeoutIndex = setTimeout(() => {
        levelStats.push({
          level: level - 2,
          outcome: "Failed",
        });
        endLevel();
      }, waitTime);
      // setTimeoutIndex(timeout);
    }
  };
  const clickedOnDots = (index) => {
    // console.log("step", step, level);
    let currentAnswerSeq = [...dotAnswerSeq];
    if (gameStarted && level * 2 === step) {
      // console.log("step", step);
      if (dots[index].selected) {
        if (dotAnswerSeq[dotAnswerSeq.length - 1] === index) {
          setDotAnswerSeq((seq) => seq.filter((i) => i !== index));
          // dotAnswerSeq.pop();
          setDots((dots) => {
            return dots.map((dot, i) => {
              if (i === index) {
                return {
                  ...dot,
                  selected: false,
                  highlighted: false,
                };
              }
              return dot;
            });
          });
          // dots[index].unSelect();
          // dots[index].unhighlight();
        } else {
          // swal("First unselect the next dot you selected");
        }
      } else {
        setDots((dots) => {
          return dots.map((dot, i) => {
            if (i === index) {
              return {
                ...dot,
                selected: true,
                order: dotAnswerSeq.length + 1,
              };
            }
            return dot;
          });
        });
        // dots[index].select(dotAnswerSeq.length + 1);
        setDotAnswerSeq((seq) => [...seq, index]);
        currentAnswerSeq = [...currentAnswerSeq, index];
        // console.log("dots", dotAnswerSeq);
        // dotAnswerSeq.push(index);
        // dots[index].selected = true;
        // dots[index].select(dotAnswerSeq.length);
      }
      // console.log("dotAnswerSeq", dotAnswerSeq);
      if (currentAnswerSeq.length === dotOrderSeq.length) {
        clearTimeout(timeoutIndex);
        let lost = currentAnswerSeq.reduce((prev, cur, index) => {
          return prev || cur !== dotOrderSeq[index];
        }, false);
        levelStats.push({
          level: level - 2,
          outcome: lost ? "Failed" : "Passed",
        });
        // Stats((levelStats) => [
        //   ...levelStats,
        //   {
        //     level: level - 2,
        //     outcome: lost ? "Failed" : "Passed",
        //   },
        // ]);
        if (!lost) {
          dotScore += level;
          // setDotScore((score) => {
          //   return score + level;
          // });
        }

        endLevel();
        return;
      }
    }
  };
  const showGridScreen = (dots) => {
    step++;
    setStartAt(Date.now());

    countdown.current.start();
    let waitTime = 7000;
    setWaitTime(waitTime);
    let randomTaskIndex = Math.floor(random(0, tasks[difficulty].length - 1));
    let currentTask = { ...tasks[difficulty][randomTaskIndex] };
    // console.log("randomTaskIndex", randomTaskIndex);
    // console.log("tasks[difficulty]", tasks[difficulty]);
    // console.log("currentTask", currentTask);
    setCurrentTask(currentTask);
    setTasks((tasks) => {
      tasks[difficulty] = tasks[difficulty].filter(
        (_, i) => i !== randomTaskIndex
      );
      return tasks;
    });
    // setTasks((tasks) =>
    //   tasks[difficulty].map((task, i) => {
    //     if (i === randomTaskIndex) {
    //       return {
    //         ...task,
    //         visited: true,
    //       };
    //     }
    //     return task;
    //   })
    // );
    // console.log("currentTask.answer", currentTask.answer);

    timeoutIndex = setTimeout(() => continueToDotScreen(dots), waitTime);
    // setTimeoutIndex(timeout);
  };
  const showDotsScreen = (dots) => {
    let done = false;
    let index;
    // console.log("dots", dots);
    while (!done) {
      index = Math.floor(random(0, dots.length - 1));
      // console.log("index", index);
      done = !dots[index].visited;
    }
    setStartAt(Date.now());
    countdown.current.start();
    // dots[index].highlight();
    setDots((dots) => {
      return dots.map((dot, i) => {
        if (index === i) {
          return {
            ...dot,
            visited: true,
            highlighted: true,
          };
        }
        return dot;
      });
    });
    let waitTime = 2000;
    setWaitTime(waitTime);

    setTimeout(() => {
      // dots[index].unhighlight();
      setDots((dots) => {
        return dots.map((dot, i) => {
          if (index === i) {
            return {
              ...dot,
              highlighted: false,
            };
          }
          return dot;
        });
      });
    }, waitTime);
    timeoutIndex = setTimeout(() => showGridScreen(dots), waitTime);
    // setTimeoutIndex(timeout);
    setDotOrderSeq((seq) => [...seq, index]);
  };
  const startGame = () => {
    setDots([]);
    step = 0;
    setDotOrderSeq([]);
    setDotAnswerSeq([]);
    const calculatedDots = [];
    let retry = 0;
    let no_of_retries = 10000;
    let number_of_dots = numberOfDots;

    const height = game_box.height * 0.75;
    const width = game_box.width;
    // const top = game_box.top;
    // const left = game_box.left;
    while (number_of_dots-- && retry < no_of_retries) {
      let inserted = false;
      retry = 0;
      while (!inserted && retry < no_of_retries) {
        retry++;
        let x = Math.ceil(random(dotSize / 2, width - dotSize / 2));
        let y = Math.ceil(random(dotSize / 2, height - dotSize / 2));
        let correct = false;
        if (
          x < width - dotSize &&
          x > dotSize &&
          y < height - dotSize &&
          y > dotSize
        ) {
          correct = true;
          for (let i = 0; i < calculatedDots.length; i++) {
            if (
              dist(x, y, calculatedDots[i].x, calculatedDots[i].y) <
              dotSize * 2
            ) {
              correct = false;
              break;
            }
          }
        }
        if (correct) {
          // calculatedDots.push(new Dot(x, y));
          calculatedDots.push({
            x,
            y,
            highlighted: false,
            order: "",
            selected: false,
          });
        }
        inserted = correct;
      }
    }
    setDots(calculatedDots);
    setGameStarted(true);
    showDotsScreen(calculatedDots);
  };

  useEffect(() => {
    setGameBox(document.getElementById("game-box").getBoundingClientRect());

    return () => {
      clearTimeout(timeoutIndex);
    };
  }, [window.location.href]);
  // console.log("dots", level, step);

  return (
    <main className="h-screen flex justify-center items-center cols">
      <div className="w-128 h-screen bg-black-333 flex flex-col items-center border">
        <div className="w-full py-3 flex justify-between px-5 bg-gray-F7">
          <div className="flex justify-center items-center">
            <button
              className="mr-3 bg-gray-F8 rounded-md font-medium"
              onClick={(e) => {
                e.preventDefault();
                history.push("/");
              }}
            >
              <img src={BackIcon} className="h-5 w-5" alt="go back" />
            </button>
            <div className="capitalize">{`Grid Challenge ${
              difficulty ? `- ${difficulty}` : ""
            }`}</div>
          </div>

          <Countdown
            date={startAt + waitTime}
            // onComplete={() => setCurrentQuestion(x.length)}
            ref={countdown}
            autoStart={true}
            precision={3}
            renderer={({
              completed,
              formatted: { hours, minutes, seconds },
            }) => {
              return (
                <div>
                  {gameStarted ? (gameOver ? "00" : minutes) : "00"}:
                  {gameStarted ? (gameOver ? "00" : seconds) : "00"}
                </div>
              );
            }}
          />
        </div>

        <div
          id="game-box"
          className="flex h-full values-center items-center w-full justify-center"
        >
          {gameStarted ? (
            !gameOver ? (
              step % 2 === 0 ? (
                <div
                  className="w-full bg-green-200 relative"
                  style={{ height: "75%" }}
                >
                  {dots.map((dot, index) => {
                    return (
                      <Dots
                        x={dot.x}
                        y={dot.y}
                        order={dot.order}
                        highlighted={dot.highlighted}
                        selected={dot.selected}
                        size={dotSize}
                        index={index}
                        clicked={clickedOnDots}
                      />
                    );
                  })}
                </div>
              ) : (
                <div className="flex flex-col justify-center h-full gap-2 px-3">
                  {gameStarted && !gameOver && step % 2 !== 0 ? (
                    <div className="w-full text-xl font-medium text-center text-white">
                      Is it symmetric?
                    </div>
                  ) : null}
                  <img
                    className="w-full object-contain"
                    src={currentTask.image}
                  />
                  {gameStarted && !gameOver && step % 2 !== 0 ? (
                    <div className="flex gap-3 w-full">
                      <button
                        className="w-full text-center px-3 py-2 text-white font-medium bg-blue"
                        onClick={() => {
                          if (currentTask.answer === true) {
                            taskScore++;
                            // setTaskScore((score) => score + 1);
                          }
                          clearTimeout(timeoutIndex);
                          continueToDotScreen(dots);
                        }}
                      >
                        Yes
                      </button>
                      <button
                        className="w-full text-center px-3 py-2 text-white font-medium bg-blue"
                        onClick={() => {
                          if (currentTask.answer === false) {
                            taskScore++;
                            // setTaskScore((score) => score + 1);
                          }
                          clearTimeout(timeoutIndex);
                          continueToDotScreen(dots);
                        }}
                      >
                        No
                      </button>
                    </div>
                  ) : null}
                </div>
              )
            ) : (
              <div className="flex flex-col justify-center items-center">
                <div className="text-2xl text-white">Game Over</div>
                {/* <div className="text-lg text-white">{`Score: ${score}`}</div> */}
              </div>
            )
          ) : (
            <div className="flex flex-col w-full h-full">
              {record ? (
                <div className="sm:flex grid  grid-cols-2  w-full justify-center">
                  {Object.keys(record).map((level, i) => {
                    return (
                      <div
                        className={`flex flex-col gap-3 ${
                          highScoreColor[level]
                        } p-5 bg-opacity-75 w-full ${
                          i === 2 ? "col-span-2" : ""
                        }`}
                      >
                        <div className="capitalize text-white text-xl font-medium">
                          {level}
                        </div>
                        <div className="whitespace-pre-wrap text-white">
                          {[
                            // ...record[level].levelStats.map(
                            //   (stat) => `Level ${stat.level} = ${stat.outcome}`
                            // ),
                            `Dot Score = ${record[level].dotScore}/12`,
                            `Subtask Score = ${record[level].taskScore}/12`,
                          ].join("\n")}
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : null}
              <div className="flex flex-col  gap-5 justify-center items-center h-full w-full">
                <div>
                  <button
                    className="py-2 px-5 bg-gray-F8 rounded-md font-medium text-center"
                    onClick={() => {
                      swal({
                        text: `1. The goal is to remember where and in what order the dots appear on each grid
2. There's only a ccertain amount of time to remember the position of the dot
3. In between grids, the participants selects whether or not the two pictures displayed are symmetrical before the NEXT DOT appears.
4. Next the challenge is to try to select where, and in which order the dots appeared in the previous grids.
5. The correct solution opens the next level
6. Recall the correct sequence of dots while solving various dots`,
                        title: "Instructions",
                      });
                    }}
                  >
                    Game Instructions
                  </button>
                </div>
                <div className="font-medium text-white">Select difficulty</div>

                <div className="flex gap-5">
                  <button
                    className="py-2 px-5 bg-gray-F8 rounded-md font-medium text-center"
                    onClick={() => {
                      difficulty = "easy";
                      startGame();
                    }}
                  >
                    Easy
                  </button>
                  <button
                    className="py-2 px-5 bg-gray-F8 rounded-md font-medium text-center"
                    onClick={() => {
                      difficulty = "medium";
                      startGame();
                    }}
                  >
                    Medium
                  </button>
                  <button
                    className="py-2 px-5 bg-gray-F8 rounded-md font-medium text-center"
                    onClick={() => {
                      difficulty = "hard";
                      startGame();
                    }}
                  >
                    Hard
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </main>
  );
}

export default GridChallenge;
