import React, { useState, useEffect, useContext } from "react";
import { Switch, Route, useHistory, Redirect } from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/Login";
import AuthContext from "./pages/AuthContext";
import Sudoku from "./pages/Sudoku";
import MCQ from "./pages/MCQ";
import { GridChallenge, GridChallenge2 } from "./pages/girdChallenge";
import MotionChallenge from "./pages/motionChallenge";
import firebase, { FirebaseContext } from "./firebase";
import { toast } from "react-toastify";
import ProtectedRoute from "./ProtectedRoute";
import LogRocket from "logrocket";
// let a = [
// ];
function App() {
  const [currentUser, setCurrentUser] = useState(null);
  const { setIsSigningIn } = useContext(AuthContext);
  const [pending, setPending] = useState(true);
  // console.log(
  //   a.map((el) => {
  //     return {
  //       gridSize: 5,
  //       answer: el["A"],
  //       question: el["?"],
  //       gridState: Object.keys(el)
  //         .filter((el) => el !== "A" && el !== "?")
  //         .reduce((prev, cur) => {
  //           return { ...prev, [cur]: el[cur] };
  //         }, {}),
  //     };
  //   })
  // );
  // console.log(
  // a.map((el, i) => {
  //   return {
  //     question: `Q${i + 1}`,
  //     answer: el.reduce((prev, cur) => {
  //       return { ...prev, [cur - 1]: true };
  //     }, {}),
  //     options: [`Q${i + 1}O1`, `Q${i + 1}O2`, `Q${i + 1}O3`, `Q${i + 1}O4`],
  //   };
  // })
  // );

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(
      async (user) => {
        try {
          if (user) {
            // console.log("user", user);

            fetch(
              ` https://us-central1-studyowl-games.cloudfunctions.net/processSignUp/vedic?emailId=${user.email}`,
              { method: "GET" }
            )
              .then(async (response) => {
                if (response.status === 200) {
                  //allowed
                  setCurrentUser(user);
                  LogRocket.identify(user.uid, {
                    name: user.displayName,
                    email: user.email,
                  });
                } else {
                  //not allowed
                  firebase.auth().signOut();
                  const resMessage = await response.text();
                  toast.error(resMessage);
                  console.log("Something went wrong" + resMessage);
                }
                setPending(false);
                setIsSigningIn(false);
              })
              .catch((error) => {
                firebase.auth().signOut();
                setIsSigningIn(false);
                toast.error("Cannot contact server");
                console.log("Cannot contact server" + JSON.stringify(error));
                setPending(false);
              });
          } else {
            setPending(false);
            setIsSigningIn(false);
            setCurrentUser(null);
          }
        } catch (error) {
          setPending(false);
          setIsSigningIn(false);
          firebase.auth().signOut();
          console.log("error", error);
        }
      },
      () => {
        // localStorage.removeItem("authUser");
      }
    );
    return () => {
      unsubscribe();
    }; // eslint-disable-next-line
  }, []);
  const history = useHistory();
  // console.log("currentUser", currentUser);

  if (pending) {
    return (
      <div className="w-full h-screen flex justify-center items-center">
        <div className="text-lg font-medium">Loading...</div>
      </div>
    );
  }
  if (!currentUser) {
    return (
      <Switch>
        <Route path="/" exact component={Login} />
        <Redirect to="/" />
      </Switch>
    );
  }
  return (
    <FirebaseContext.Provider
      value={{
        firebase,
        currentUser,
      }}
    >
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/sudoku" exact component={Sudoku} />
        <Route path="/pattern" exact component={MCQ} />
        <Route path="/grid" exact component={GridChallenge} />
        <Route path="/grid2" exact component={GridChallenge2} />
        <Route path="/motion" exact component={MotionChallenge} />
        <Route
          path="/"
          render={() => {
            return (
              <div className="h-screen w-screen flex flex-col justify-center items-center bg-black-222">
                <div className="text-6xl text-white">404 Not Found</div>
                <button
                  className="py-2 px-5 bg-gray-F8 rounded-md font-medium m-0"
                  onClick={() => {
                    history.replace("/");
                  }}
                >
                  Home
                </button>
              </div>
            );
          }}
        />
      </Switch>
    </FirebaseContext.Provider>
  );
}

export default App;
