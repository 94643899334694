import googleLogo from "./googleLogo.svg";

import L1Q1 from "./L1Q1.png";
import L1Q2 from "./L1Q2.png";
import L1Q3 from "./L1Q3.png";
import L1Q4 from "./L1Q4.png";
import L1Q5 from "./L1Q5.png";
import L1Q6 from "./L1Q6.png";
import L1Q7 from "./L1Q7.png";
import L1Q8 from "./L1Q8.png";
import L1Q9 from "./L1Q9.png";
import L1Q10 from "./L1Q10.png";
import L1Q11 from "./L1Q11.png";
import L1Q12 from "./L1Q12.png";
import L1Q13 from "./L1Q13.png";
import L1Q14 from "./L1Q14.png";
import L1Q15 from "./L1Q15.png";
import L1Q16 from "./L1Q16.png";
import L1Q17 from "./L1Q17.png";
import L1Q18 from "./L1Q18.png";
import L1Q19 from "./L1Q19.png";
import L1Q20 from "./L1Q20.png";
import L1Q21 from "./L1Q21.png";
import L1Q22 from "./L1Q22.png";
import L1Q23 from "./L1Q23.png";
import L1Q24 from "./L1Q24.png";
import L1Q25 from "./L1Q25.png";
import L1Q26 from "./L1Q26.png";
import L1Q27 from "./L1Q27.png";
import L1Q28 from "./L1Q28.png";
import L1Q29 from "./L1Q29.png";
import L1Q30 from "./L1Q30.png";
import L1Q31 from "./L1Q31.png";
import L1Q32 from "./L1Q32.png";
import L1Q33 from "./L1Q33.png";
import L1Q34 from "./L1Q34.png";
import L1Q35 from "./L1Q35.png";
import L1Q36 from "./L1Q36.png";
import L1Q37 from "./L1Q37.png";
import L1Q38 from "./L1Q38.png";
import L1Q39 from "./L1Q39.png";
import L1Q40 from "./L1Q40.png";
import L1Q41 from "./L1Q41.png";
import L1Q42 from "./L1Q42.png";
import L1Q43 from "./L1Q43.png";
import L1Q44 from "./L1Q44.png";
import L1Q45 from "./L1Q45.png";
import L1Q46 from "./L1Q46.png";
import L1Q47 from "./L1Q47.png";
import L1Q48 from "./L1Q48.png";
import L1Q49 from "./L1Q49.png";
import L1Q50 from "./L1Q50.png";
import L1Q51 from "./L1Q51.png";
import L1Q52 from "./L1Q52.png";
import L1Q53 from "./L1Q53.png";
import L1Q54 from "./L1Q54.png";
import L1Q55 from "./L1Q55.png";
import L1Q56 from "./L1Q56.png";
import L1Q57 from "./L1Q57.png";
import L1Q58 from "./L1Q58.png";
import L1Q59 from "./L1Q59.png";
import L1Q60 from "./L1Q60.png";
import L1Q61 from "./L1Q61.png";
import L1Q62 from "./L1Q62.png";
import L1Q63 from "./L1Q63.png";
import L1Q64 from "./L1Q64.png";
import L1Q65 from "./L1Q65.png";

import L2Q1 from "./L2Q1.png";
import L2Q2 from "./L2Q2.png";
import L2Q3 from "./L2Q3.png";
import L2Q4 from "./L2Q4.png";
import L2Q5 from "./L2Q5.png";
import L2Q6 from "./L2Q6.png";
import L2Q7 from "./L2Q7.png";
import L2Q8 from "./L2Q8.png";
import L2Q9 from "./L2Q9.png";
import L2Q10 from "./L2Q10.png";
import L2Q11 from "./L2Q11.png";
import L2Q12 from "./L2Q12.png";
import L2Q13 from "./L2Q13.png";
import L2Q14 from "./L2Q14.png";
import L2Q15 from "./L2Q15.png";
import L2Q16 from "./L2Q16.png";
import L2Q17 from "./L2Q17.png";
import L2Q18 from "./L2Q18.png";
import L2Q19 from "./L2Q19.png";
import L2Q20 from "./L2Q20.png";

import L3Q1 from "./L3Q1.png";
import L3Q2 from "./L3Q2.png";
import L3Q3 from "./L3Q3.png";
import L3Q4 from "./L3Q4.png";
import L3Q5 from "./L3Q5.png";
import L3Q6 from "./L3Q6.png";
import L3Q7 from "./L3Q7.png";
import L3Q8 from "./L3Q8.png";
import L3Q9 from "./L3Q9.png";
import L3Q10 from "./L3Q10.png";
import L3Q11 from "./L3Q11.png";
import L3Q12 from "./L3Q12.png";
import L3Q13 from "./L3Q13.png";
import L3Q14 from "./L3Q14.png";
import L3Q15 from "./L3Q15.png";

import Q1 from "./Q1.png";
import Q1O1 from "./Q1O1.png";
import Q1O2 from "./Q1O2.png";
import Q1O3 from "./Q1O3.png";
import Q1O4 from "./Q1O4.png";
import Q2 from "./Q2.png";
import Q2O1 from "./Q2O1.png";
import Q2O2 from "./Q2O2.png";
import Q2O3 from "./Q2O3.png";
import Q2O4 from "./Q2O4.png";
import Q3 from "./Q3.png";
import Q3O1 from "./Q3O1.png";
import Q3O2 from "./Q3O2.png";
import Q3O3 from "./Q3O3.png";
import Q3O4 from "./Q3O4.png";
import Q4 from "./Q4.png";
import Q4O1 from "./Q4O1.png";
import Q4O2 from "./Q4O2.png";
import Q4O3 from "./Q4O3.png";
import Q4O4 from "./Q4O4.png";
import Q5 from "./Q5.png";
import Q5O1 from "./Q5O1.png";
import Q5O2 from "./Q5O2.png";
import Q5O3 from "./Q5O3.png";
import Q5O4 from "./Q5O4.png";
import Q6 from "./Q6.png";
import Q6O1 from "./Q6O1.png";
import Q6O2 from "./Q6O2.png";
import Q6O3 from "./Q6O3.png";
import Q6O4 from "./Q6O4.png";
import Q7 from "./Q7.png";
import Q7O1 from "./Q7O1.png";
import Q7O2 from "./Q7O2.png";
import Q7O3 from "./Q7O3.png";
import Q7O4 from "./Q7O4.png";
import Q8 from "./Q8.png";
import Q8O1 from "./Q8O1.png";
import Q8O2 from "./Q8O2.png";
import Q8O3 from "./Q8O3.png";
import Q8O4 from "./Q8O4.png";
import Q9 from "./Q9.png";
import Q9O1 from "./Q9O1.png";
import Q9O2 from "./Q9O2.png";
import Q9O3 from "./Q9O3.png";
import Q9O4 from "./Q9O4.png";
import Q10 from "./Q10.png";
import Q10O1 from "./Q10O1.png";
import Q10O2 from "./Q10O2.png";
import Q10O3 from "./Q10O3.png";
import Q10O4 from "./Q10O4.png";
import Q11 from "./Q11.png";
import Q11O1 from "./Q11O1.png";
import Q11O2 from "./Q11O2.png";
import Q11O3 from "./Q11O3.png";
import Q11O4 from "./Q11O4.png";
import Q12 from "./Q12.png";
import Q12O1 from "./Q12O1.png";
import Q12O2 from "./Q12O2.png";
import Q12O3 from "./Q12O3.png";
import Q12O4 from "./Q12O4.png";
import Q13 from "./Q13.png";
import Q13O1 from "./Q13O1.png";
import Q13O2 from "./Q13O2.png";
import Q13O3 from "./Q13O3.png";
import Q13O4 from "./Q13O4.png";
import Q14 from "./Q14.png";
import Q14O1 from "./Q14O1.png";
import Q14O2 from "./Q14O2.png";
import Q14O3 from "./Q14O3.png";
import Q14O4 from "./Q14O4.png";
import Q15 from "./Q15.png";
import Q15O1 from "./Q15O1.png";
import Q15O2 from "./Q15O2.png";
import Q15O3 from "./Q15O3.png";
import Q15O4 from "./Q15O4.png";
import Q16 from "./Q16.png";
import Q16O1 from "./Q16O1.png";
import Q16O2 from "./Q16O2.png";
import Q16O3 from "./Q16O3.png";
import Q16O4 from "./Q16O4.png";
import Q17 from "./Q17.png";
import Q17O1 from "./Q17O1.png";
import Q17O2 from "./Q17O2.png";
import Q17O3 from "./Q17O3.png";
import Q17O4 from "./Q17O4.png";
import Q18 from "./Q18.png";
import Q18O1 from "./Q18O1.png";
import Q18O2 from "./Q18O2.png";
import Q18O3 from "./Q18O3.png";
import Q18O4 from "./Q18O4.png";
import Q19 from "./Q19.png";
import Q19O1 from "./Q19O1.png";
import Q19O2 from "./Q19O2.png";
import Q19O3 from "./Q19O3.png";
import Q19O4 from "./Q19O4.png";
import Q20 from "./Q20.png";
import Q20O1 from "./Q20O1.png";
import Q20O2 from "./Q20O2.png";
import Q20O3 from "./Q20O3.png";
import Q20O4 from "./Q20O4.png";
import Q21 from "./Q21.png";
import Q21O1 from "./Q21O1.png";
import Q21O2 from "./Q21O2.png";
import Q21O3 from "./Q21O3.png";
import Q21O4 from "./Q21O4.png";
import Q22 from "./Q22.png";
import Q22O1 from "./Q22O1.png";
import Q22O2 from "./Q22O2.png";
import Q22O3 from "./Q22O3.png";
import Q22O4 from "./Q22O4.png";
import Q23 from "./Q23.png";
import Q23O1 from "./Q23O1.png";
import Q23O2 from "./Q23O2.png";
import Q23O3 from "./Q23O3.png";
import Q23O4 from "./Q23O4.png";
import Q24 from "./Q24.png";
import Q24O1 from "./Q24O1.png";
import Q24O2 from "./Q24O2.png";
import Q24O3 from "./Q24O3.png";
import Q24O4 from "./Q24O4.png";
import Q25 from "./Q25.png";
import Q25O1 from "./Q25O1.png";
import Q25O2 from "./Q25O2.png";
import Q25O3 from "./Q25O3.png";
import Q25O4 from "./Q25O4.png";
import Q26 from "./Q26.png";
import Q26O1 from "./Q26O1.png";
import Q26O2 from "./Q26O2.png";
import Q26O3 from "./Q26O3.png";
import Q26O4 from "./Q26O4.png";
import Q27 from "./Q27.png";
import Q27O1 from "./Q27O1.png";
import Q27O2 from "./Q27O2.png";
import Q27O3 from "./Q27O3.png";
import Q27O4 from "./Q27O4.png";
import Q28 from "./Q28.png";
import Q28O1 from "./Q28O1.png";
import Q28O2 from "./Q28O2.png";
import Q28O3 from "./Q28O3.png";
import Q28O4 from "./Q28O4.png";
import Q29 from "./Q29.png";
import Q29O1 from "./Q29O1.png";
import Q29O2 from "./Q29O2.png";
import Q29O3 from "./Q29O3.png";
import Q29O4 from "./Q29O4.png";
import Q30 from "./Q30.png";
import Q30O1 from "./Q30O1.png";
import Q30O2 from "./Q30O2.png";
import Q30O3 from "./Q30O3.png";
import Q30O4 from "./Q30O4.png";
import Q31 from "./Q31.png";
import Q31O1 from "./Q31O1.png";
import Q31O2 from "./Q31O2.png";
import Q31O3 from "./Q31O3.png";
import Q31O4 from "./Q31O4.png";
import Q32 from "./Q32.png";
import Q32O1 from "./Q32O1.png";
import Q32O2 from "./Q32O2.png";
import Q32O3 from "./Q32O3.png";
import Q32O4 from "./Q32O4.png";
import Q33 from "./Q33.png";
import Q33O1 from "./Q33O1.png";
import Q33O2 from "./Q33O2.png";
import Q33O3 from "./Q33O3.png";
import Q33O4 from "./Q33O4.png";
import Q34 from "./Q34.png";
import Q34O1 from "./Q34O1.png";
import Q34O2 from "./Q34O2.png";
import Q34O3 from "./Q34O3.png";
import Q34O4 from "./Q34O4.png";
import Q35 from "./Q35.png";
import Q35O1 from "./Q35O1.png";
import Q35O2 from "./Q35O2.png";
import Q35O3 from "./Q35O3.png";
import Q35O4 from "./Q35O4.png";
import Q36 from "./Q36.png";
import Q36O1 from "./Q36O1.png";
import Q36O2 from "./Q36O2.png";
import Q36O3 from "./Q36O3.png";
import Q36O4 from "./Q36O4.png";
import Q37 from "./Q37.png";
import Q37O1 from "./Q37O1.png";
import Q37O2 from "./Q37O2.png";
import Q37O3 from "./Q37O3.png";
import Q37O4 from "./Q37O4.png";
import Q38 from "./Q38.png";
import Q38O1 from "./Q38O1.png";
import Q38O2 from "./Q38O2.png";
import Q38O3 from "./Q38O3.png";
import Q38O4 from "./Q38O4.png";
import Q39 from "./Q39.png";
import Q39O1 from "./Q39O1.png";
import Q39O2 from "./Q39O2.png";
import Q39O3 from "./Q39O3.png";
import Q39O4 from "./Q39O4.png";
import Q40 from "./Q40.png";
import Q40O1 from "./Q40O1.png";
import Q40O2 from "./Q40O2.png";
import Q40O3 from "./Q40O3.png";
import Q40O4 from "./Q40O4.png";
import Q41 from "./Q41.png";
import Q41O1 from "./Q41O1.png";
import Q41O2 from "./Q41O2.png";
import Q41O3 from "./Q41O3.png";
import Q41O4 from "./Q41O4.png";
import Q42 from "./Q42.png";
import Q42O1 from "./Q42O1.png";
import Q42O2 from "./Q42O2.png";
import Q42O3 from "./Q42O3.png";
import Q42O4 from "./Q42O4.png";
import Q43 from "./Q43.png";
import Q43O1 from "./Q43O1.png";
import Q43O2 from "./Q43O2.png";
import Q43O3 from "./Q43O3.png";
import Q43O4 from "./Q43O4.png";
import Q44 from "./Q44.png";
import Q44O1 from "./Q44O1.png";
import Q44O2 from "./Q44O2.png";
import Q44O3 from "./Q44O3.png";
import Q44O4 from "./Q44O4.png";
import Q45 from "./Q45.png";
import Q45O1 from "./Q45O1.png";
import Q45O2 from "./Q45O2.png";
import Q45O3 from "./Q45O3.png";
import Q45O4 from "./Q45O4.png";
import Q46 from "./Q46.png";
import Q46O1 from "./Q46O1.png";
import Q46O2 from "./Q46O2.png";
import Q46O3 from "./Q46O3.png";
import Q46O4 from "./Q46O4.png";
import Q47 from "./Q47.png";
import Q47O1 from "./Q47O1.png";
import Q47O2 from "./Q47O2.png";
import Q47O3 from "./Q47O3.png";
import Q47O4 from "./Q47O4.png";
import Q48 from "./Q48.png";
import Q48O1 from "./Q48O1.png";
import Q48O2 from "./Q48O2.png";
import Q48O3 from "./Q48O3.png";
import Q48O4 from "./Q48O4.png";
import Q49 from "./Q49.png";
import Q49O1 from "./Q49O1.png";
import Q49O2 from "./Q49O2.png";
import Q49O3 from "./Q49O3.png";
import Q49O4 from "./Q49O4.png";
import Q50 from "./Q50.png";
import Q50O1 from "./Q50O1.png";
import Q50O2 from "./Q50O2.png";
import Q50O3 from "./Q50O3.png";
import Q50O4 from "./Q50O4.png";
import Q51 from "./Q51.png";
import Q51O1 from "./Q51O1.png";
import Q51O2 from "./Q51O2.png";
import Q51O3 from "./Q51O3.png";
import Q51O4 from "./Q51O4.png";
import Q52 from "./Q52.png";
import Q52O1 from "./Q52O1.png";
import Q52O2 from "./Q52O2.png";
import Q52O3 from "./Q52O3.png";
import Q52O4 from "./Q52O4.png";
import Q53 from "./Q53.png";
import Q53O1 from "./Q53O1.png";
import Q53O2 from "./Q53O2.png";
import Q53O3 from "./Q53O3.png";
import Q53O4 from "./Q53O4.png";
import Q54 from "./Q54.png";
import Q54O1 from "./Q54O1.png";
import Q54O2 from "./Q54O2.png";
import Q54O3 from "./Q54O3.png";
import Q54O4 from "./Q54O4.png";
import Q55 from "./Q55.png";
import Q55O1 from "./Q55O1.png";
import Q55O2 from "./Q55O2.png";
import Q55O3 from "./Q55O3.png";
import Q55O4 from "./Q55O4.png";
import Q56 from "./Q56.png";
import Q56O1 from "./Q56O1.png";
import Q56O2 from "./Q56O2.png";
import Q56O3 from "./Q56O3.png";
import Q56O4 from "./Q56O4.png";
import Q57 from "./Q57.png";
import Q57O1 from "./Q57O1.png";
import Q57O2 from "./Q57O2.png";
import Q57O3 from "./Q57O3.png";
import Q57O4 from "./Q57O4.png";
import Q58 from "./Q58.png";
import Q58O1 from "./Q58O1.png";
import Q58O2 from "./Q58O2.png";
import Q58O3 from "./Q58O3.png";
import Q58O4 from "./Q58O4.png";
import Q59 from "./Q59.png";
import Q59O1 from "./Q59O1.png";
import Q59O2 from "./Q59O2.png";
import Q59O3 from "./Q59O3.png";
import Q59O4 from "./Q59O4.png";
import Q60 from "./Q60.png";
import Q60O1 from "./Q60O1.png";
import Q60O2 from "./Q60O2.png";
import Q60O3 from "./Q60O3.png";
import Q60O4 from "./Q60O4.png";
import Q61 from "./Q61.png";
import Q61O1 from "./Q61O1.png";
import Q61O2 from "./Q61O2.png";
import Q61O3 from "./Q61O3.png";
import Q61O4 from "./Q61O4.png";
import Q62 from "./Q62.png";
import Q62O1 from "./Q62O1.png";
import Q62O2 from "./Q62O2.png";
import Q62O3 from "./Q62O3.png";
import Q62O4 from "./Q62O4.png";
import Q63 from "./Q63.png";
import Q63O1 from "./Q63O1.png";
import Q63O2 from "./Q63O2.png";
import Q63O3 from "./Q63O3.png";
import Q63O4 from "./Q63O4.png";
import Q64 from "./Q64.png";
import Q64O1 from "./Q64O1.png";
import Q64O2 from "./Q64O2.png";
import Q64O3 from "./Q64O3.png";
import Q64O4 from "./Q64O4.png";
import Q65 from "./Q65.png";
import Q65O1 from "./Q65O1.png";
import Q65O2 from "./Q65O2.png";
import Q65O3 from "./Q65O3.png";
import Q65O4 from "./Q65O4.png";
import Q66 from "./Q66.png";
import Q66O1 from "./Q66O1.png";
import Q66O2 from "./Q66O2.png";
import Q66O3 from "./Q66O3.png";
import Q66O4 from "./Q66O4.png";
import Q67 from "./Q67.png";
import Q67O1 from "./Q67O1.png";
import Q67O2 from "./Q67O2.png";
import Q67O3 from "./Q67O3.png";
import Q67O4 from "./Q67O4.png";
import Q68 from "./Q68.png";
import Q68O1 from "./Q68O1.png";
import Q68O2 from "./Q68O2.png";
import Q68O3 from "./Q68O3.png";
import Q68O4 from "./Q68O4.png";
import Q69 from "./Q69.png";
import Q69O1 from "./Q69O1.png";
import Q69O2 from "./Q69O2.png";
import Q69O3 from "./Q69O3.png";
import Q69O4 from "./Q69O4.png";
import Q70 from "./Q70.png";
import Q70O1 from "./Q70O1.png";
import Q70O2 from "./Q70O2.png";
import Q70O3 from "./Q70O3.png";
import Q70O4 from "./Q70O4.png";
import Q71 from "./Q71.png";
import Q71O1 from "./Q71O1.png";
import Q71O2 from "./Q71O2.png";
import Q71O3 from "./Q71O3.png";
import Q71O4 from "./Q71O4.png";
import Q72 from "./Q72.png";
import Q72O1 from "./Q72O1.png";
import Q72O2 from "./Q72O2.png";
import Q72O3 from "./Q72O3.png";
import Q72O4 from "./Q72O4.png";
import Q73 from "./Q73.png";
import Q73O1 from "./Q73O1.png";
import Q73O2 from "./Q73O2.png";
import Q73O3 from "./Q73O3.png";
import Q73O4 from "./Q73O4.png";
import Q74 from "./Q74.png";
import Q74O1 from "./Q74O1.png";
import Q74O2 from "./Q74O2.png";
import Q74O3 from "./Q74O3.png";
import Q74O4 from "./Q74O4.png";
import Q75 from "./Q75.png";
import Q75O1 from "./Q75O1.png";
import Q75O2 from "./Q75O2.png";
import Q75O3 from "./Q75O3.png";
import Q75O4 from "./Q75O4.png";
import Q76 from "./Q76.png";
import Q76O1 from "./Q76O1.png";
import Q76O2 from "./Q76O2.png";
import Q76O3 from "./Q76O3.png";
import Q76O4 from "./Q76O4.png";
import Q77 from "./Q77.png";
import Q77O1 from "./Q77O1.png";
import Q77O2 from "./Q77O2.png";
import Q77O3 from "./Q77O3.png";
import Q77O4 from "./Q77O4.png";
import Q78 from "./Q78.png";
import Q78O1 from "./Q78O1.png";
import Q78O2 from "./Q78O2.png";
import Q78O3 from "./Q78O3.png";
import Q78O4 from "./Q78O4.png";
import Q79 from "./Q79.png";
import Q79O1 from "./Q79O1.png";
import Q79O2 from "./Q79O2.png";
import Q79O3 from "./Q79O3.png";
import Q79O4 from "./Q79O4.png";
import Q80 from "./Q80.png";
import Q80O1 from "./Q80O1.png";
import Q80O2 from "./Q80O2.png";
import Q80O3 from "./Q80O3.png";
import Q80O4 from "./Q80O4.png";
import Q81 from "./Q81.png";
import Q81O1 from "./Q81O1.png";
import Q81O2 from "./Q81O2.png";
import Q81O3 from "./Q81O3.png";
import Q81O4 from "./Q81O4.png";
import Q82 from "./Q82.png";
import Q82O1 from "./Q82O1.png";
import Q82O2 from "./Q82O2.png";
import Q82O3 from "./Q82O3.png";
import Q82O4 from "./Q82O4.png";
import Q83 from "./Q83.png";
import Q83O1 from "./Q83O1.png";
import Q83O2 from "./Q83O2.png";
import Q83O3 from "./Q83O3.png";
import Q83O4 from "./Q83O4.png";
import Q84 from "./Q84.png";
import Q84O1 from "./Q84O1.png";
import Q84O2 from "./Q84O2.png";
import Q84O3 from "./Q84O3.png";
import Q84O4 from "./Q84O4.png";
import Q85 from "./Q85.png";
import Q85O1 from "./Q85O1.png";
import Q85O2 from "./Q85O2.png";
import Q85O3 from "./Q85O3.png";
import Q85O4 from "./Q85O4.png";
import Q86 from "./Q86.png";
import Q86O1 from "./Q86O1.png";
import Q86O2 from "./Q86O2.png";
import Q86O3 from "./Q86O3.png";
import Q86O4 from "./Q86O4.png";
import Q87 from "./Q87.png";
import Q87O1 from "./Q87O1.png";
import Q87O2 from "./Q87O2.png";
import Q87O3 from "./Q87O3.png";
import Q87O4 from "./Q87O4.png";
import Q88 from "./Q88.png";
import Q88O1 from "./Q88O1.png";
import Q88O2 from "./Q88O2.png";
import Q88O3 from "./Q88O3.png";
import Q88O4 from "./Q88O4.png";
import Q89 from "./Q89.png";
import Q89O1 from "./Q89O1.png";
import Q89O2 from "./Q89O2.png";
import Q89O3 from "./Q89O3.png";
import Q89O4 from "./Q89O4.png";
import Q90 from "./Q90.png";
import Q90O1 from "./Q90O1.png";
import Q90O2 from "./Q90O2.png";
import Q90O3 from "./Q90O3.png";
import Q90O4 from "./Q90O4.png";
import Q91 from "./Q91.png";
import Q91O1 from "./Q91O1.png";
import Q91O2 from "./Q91O2.png";
import Q91O3 from "./Q91O3.png";
import Q91O4 from "./Q91O4.png";
import Q92 from "./Q92.png";
import Q92O1 from "./Q92O1.png";
import Q92O2 from "./Q92O2.png";
import Q92O3 from "./Q92O3.png";
import Q92O4 from "./Q92O4.png";
import Q93 from "./Q93.png";
import Q93O1 from "./Q93O1.png";
import Q93O2 from "./Q93O2.png";
import Q93O3 from "./Q93O3.png";
import Q93O4 from "./Q93O4.png";
import Q94 from "./Q94.png";
import Q94O1 from "./Q94O1.png";
import Q94O2 from "./Q94O2.png";
import Q94O3 from "./Q94O3.png";
import Q94O4 from "./Q94O4.png";
import Q95 from "./Q95.png";
import Q95O1 from "./Q95O1.png";
import Q95O2 from "./Q95O2.png";
import Q95O3 from "./Q95O3.png";
import Q95O4 from "./Q95O4.png";
import Q96 from "./Q96.png";
import Q96O1 from "./Q96O1.png";
import Q96O2 from "./Q96O2.png";
import Q96O3 from "./Q96O3.png";
import Q96O4 from "./Q96O4.png";
import Q97 from "./Q97.png";
import Q97O1 from "./Q97O1.png";
import Q97O2 from "./Q97O2.png";
import Q97O3 from "./Q97O3.png";
import Q97O4 from "./Q97O4.png";
import Q98 from "./Q98.png";
import Q98O1 from "./Q98O1.png";
import Q98O2 from "./Q98O2.png";
import Q98O3 from "./Q98O3.png";
import Q98O4 from "./Q98O4.png";
import Q99 from "./Q99.png";
import Q99O1 from "./Q99O1.png";
import Q99O2 from "./Q99O2.png";
import Q99O3 from "./Q99O3.png";
import Q99O4 from "./Q99O4.png";
import Q100 from "./Q100.png";
import Q100O1 from "./Q100O1.png";
import Q100O2 from "./Q100O2.png";
import Q100O3 from "./Q100O3.png";
import Q100O4 from "./Q100O4.png";
import Q101 from "./Q101.png";
import Q101O1 from "./Q101O1.png";
import Q101O2 from "./Q101O2.png";
import Q101O3 from "./Q101O3.png";
import Q101O4 from "./Q101O4.png";
import Q102 from "./Q102.png";
import Q102O1 from "./Q102O1.png";
import Q102O2 from "./Q102O2.png";
import Q102O3 from "./Q102O3.png";
import Q102O4 from "./Q102O4.png";
import Q103 from "./Q103.png";
import Q103O1 from "./Q103O1.png";
import Q103O2 from "./Q103O2.png";
import Q103O3 from "./Q103O3.png";
import Q103O4 from "./Q103O4.png";
import Q104 from "./Q104.png";
import Q104O1 from "./Q104O1.png";
import Q104O2 from "./Q104O2.png";
import Q104O3 from "./Q104O3.png";
import Q104O4 from "./Q104O4.png";
import Q105 from "./Q105.png";
import Q105O1 from "./Q105O1.png";
import Q105O2 from "./Q105O2.png";
import Q105O3 from "./Q105O3.png";
import Q105O4 from "./Q105O4.png";
import Q106 from "./Q106.png";
import Q106O1 from "./Q106O1.png";
import Q106O2 from "./Q106O2.png";
import Q106O3 from "./Q106O3.png";
import Q106O4 from "./Q106O4.png";
import Q107 from "./Q107.png";
import Q107O1 from "./Q107O1.png";
import Q107O2 from "./Q107O2.png";
import Q107O3 from "./Q107O3.png";
import Q107O4 from "./Q107O4.png";
import Q108 from "./Q108.png";
import Q108O1 from "./Q108O1.png";
import Q108O2 from "./Q108O2.png";
import Q108O3 from "./Q108O3.png";
import Q108O4 from "./Q108O4.png";
import Q109 from "./Q109.png";
import Q109O1 from "./Q109O1.png";
import Q109O2 from "./Q109O2.png";
import Q109O3 from "./Q109O3.png";
import Q109O4 from "./Q109O4.png";
import Q110 from "./Q110.png";
import Q110O1 from "./Q110O1.png";
import Q110O2 from "./Q110O2.png";
import Q110O3 from "./Q110O3.png";
import Q110O4 from "./Q110O4.png";
import Q111 from "./Q111.png";
import Q111O1 from "./Q111O1.png";
import Q111O2 from "./Q111O2.png";
import Q111O3 from "./Q111O3.png";
import Q111O4 from "./Q111O4.png";
import Q112 from "./Q112.png";
import Q112O1 from "./Q112O1.png";
import Q112O2 from "./Q112O2.png";
import Q112O3 from "./Q112O3.png";
import Q112O4 from "./Q112O4.png";
import Q113 from "./Q113.png";
import Q113O1 from "./Q113O1.png";
import Q113O2 from "./Q113O2.png";
import Q113O3 from "./Q113O3.png";
import Q113O4 from "./Q113O4.png";
import Q114 from "./Q114.png";
import Q114O1 from "./Q114O1.png";
import Q114O2 from "./Q114O2.png";
import Q114O3 from "./Q114O3.png";
import Q114O4 from "./Q114O4.png";
import Q115 from "./Q115.png";
import Q115O1 from "./Q115O1.png";
import Q115O2 from "./Q115O2.png";
import Q115O3 from "./Q115O3.png";
import Q115O4 from "./Q115O4.png";
import Q116 from "./Q116.png";
import Q116O1 from "./Q116O1.png";
import Q116O2 from "./Q116O2.png";
import Q116O3 from "./Q116O3.png";
import Q116O4 from "./Q116O4.png";
import Q117 from "./Q117.png";
import Q117O1 from "./Q117O1.png";
import Q117O2 from "./Q117O2.png";
import Q117O3 from "./Q117O3.png";
import Q117O4 from "./Q117O4.png";
import Q118 from "./Q118.png";
import Q118O1 from "./Q118O1.png";
import Q118O2 from "./Q118O2.png";
import Q118O3 from "./Q118O3.png";
import Q118O4 from "./Q118O4.png";
import Q119 from "./Q119.png";
import Q119O1 from "./Q119O1.png";
import Q119O2 from "./Q119O2.png";
import Q119O3 from "./Q119O3.png";
import Q119O4 from "./Q119O4.png";
import Q120 from "./Q120.png";
import Q120O1 from "./Q120O1.png";
import Q120O2 from "./Q120O2.png";
import Q120O3 from "./Q120O3.png";
import Q120O4 from "./Q120O4.png";
import Q121 from "./Q121.png";
import Q121O1 from "./Q121O1.png";
import Q121O2 from "./Q121O2.png";
import Q121O3 from "./Q121O3.png";
import Q121O4 from "./Q121O4.png";
import Q122 from "./Q122.png";
import Q122O1 from "./Q122O1.png";
import Q122O2 from "./Q122O2.png";
import Q122O3 from "./Q122O3.png";
import Q122O4 from "./Q122O4.png";
import Q123 from "./Q123.png";
import Q123O1 from "./Q123O1.png";
import Q123O2 from "./Q123O2.png";
import Q123O3 from "./Q123O3.png";
import Q123O4 from "./Q123O4.png";
import Q124 from "./Q124.png";
import Q124O1 from "./Q124O1.png";
import Q124O2 from "./Q124O2.png";
import Q124O3 from "./Q124O3.png";
import Q124O4 from "./Q124O4.png";
import Q125 from "./Q125.png";
import Q125O1 from "./Q125O1.png";
import Q125O2 from "./Q125O2.png";
import Q125O3 from "./Q125O3.png";
import Q125O4 from "./Q125O4.png";
import Q126 from "./Q126.png";
import Q126O1 from "./Q126O1.png";
import Q126O2 from "./Q126O2.png";
import Q126O3 from "./Q126O3.png";
import Q126O4 from "./Q126O4.png";
import Q127 from "./Q127.png";
import Q127O1 from "./Q127O1.png";
import Q127O2 from "./Q127O2.png";
import Q127O3 from "./Q127O3.png";
import Q127O4 from "./Q127O4.png";
import Q128 from "./Q128.png";
import Q128O1 from "./Q128O1.png";
import Q128O2 from "./Q128O2.png";
import Q128O3 from "./Q128O3.png";
import Q128O4 from "./Q128O4.png";
import Q129 from "./Q129.png";
import Q129O1 from "./Q129O1.png";
import Q129O2 from "./Q129O2.png";
import Q129O3 from "./Q129O3.png";
import Q129O4 from "./Q129O4.png";
import Q130 from "./Q130.png";
import Q130O1 from "./Q130O1.png";
import Q130O2 from "./Q130O2.png";
import Q130O3 from "./Q130O3.png";
import Q130O4 from "./Q130O4.png";
import Q131 from "./Q131.png";
import Q131O1 from "./Q131O1.png";
import Q131O2 from "./Q131O2.png";
import Q131O3 from "./Q131O3.png";
import Q131O4 from "./Q131O4.png";
import Q132 from "./Q132.png";
import Q132O1 from "./Q132O1.png";
import Q132O2 from "./Q132O2.png";
import Q132O3 from "./Q132O3.png";
import Q132O4 from "./Q132O4.png";
import Q133 from "./Q133.png";
import Q133O1 from "./Q133O1.png";
import Q133O2 from "./Q133O2.png";
import Q133O3 from "./Q133O3.png";
import Q133O4 from "./Q133O4.png";
import Q134 from "./Q134.png";
import Q134O1 from "./Q134O1.png";
import Q134O2 from "./Q134O2.png";
import Q134O3 from "./Q134O3.png";
import Q134O4 from "./Q134O4.png";
import Q135 from "./Q135.png";
import Q135O1 from "./Q135O1.png";
import Q135O2 from "./Q135O2.png";
import Q135O3 from "./Q135O3.png";
import Q135O4 from "./Q135O4.png";
import Q136 from "./Q136.png";
import Q136O1 from "./Q136O1.png";
import Q136O2 from "./Q136O2.png";
import Q136O3 from "./Q136O3.png";
import Q136O4 from "./Q136O4.png";
import Q137 from "./Q137.png";
import Q137O1 from "./Q137O1.png";
import Q137O2 from "./Q137O2.png";
import Q137O3 from "./Q137O3.png";
import Q137O4 from "./Q137O4.png";
import Q138 from "./Q138.png";
import Q138O1 from "./Q138O1.png";
import Q138O2 from "./Q138O2.png";
import Q138O3 from "./Q138O3.png";
import Q138O4 from "./Q138O4.png";
import Q139 from "./Q139.png";
import Q139O1 from "./Q139O1.png";
import Q139O2 from "./Q139O2.png";
import Q139O3 from "./Q139O3.png";
import Q139O4 from "./Q139O4.png";
import Q140 from "./Q140.png";
import Q140O1 from "./Q140O1.png";
import Q140O2 from "./Q140O2.png";
import Q140O3 from "./Q140O3.png";
import Q140O4 from "./Q140O4.png";
import Q141 from "./Q141.png";
import Q141O1 from "./Q141O1.png";
import Q141O2 from "./Q141O2.png";
import Q141O3 from "./Q141O3.png";
import Q141O4 from "./Q141O4.png";
import Q142 from "./Q142.png";
import Q142O1 from "./Q142O1.png";
import Q142O2 from "./Q142O2.png";
import Q142O3 from "./Q142O3.png";
import Q142O4 from "./Q142O4.png";
import Q143 from "./Q143.png";
import Q143O1 from "./Q143O1.png";
import Q143O2 from "./Q143O2.png";
import Q143O3 from "./Q143O3.png";
import Q143O4 from "./Q143O4.png";
import Q144 from "./Q144.png";
import Q144O1 from "./Q144O1.png";
import Q144O2 from "./Q144O2.png";
import Q144O3 from "./Q144O3.png";
import Q144O4 from "./Q144O4.png";
import Q145 from "./Q145.png";
import Q145O1 from "./Q145O1.png";
import Q145O2 from "./Q145O2.png";
import Q145O3 from "./Q145O3.png";
import Q145O4 from "./Q145O4.png";
import Q146 from "./Q146.png";
import Q146O1 from "./Q146O1.png";
import Q146O2 from "./Q146O2.png";
import Q146O3 from "./Q146O3.png";
import Q146O4 from "./Q146O4.png";
import Q147 from "./Q147.png";
import Q147O1 from "./Q147O1.png";
import Q147O2 from "./Q147O2.png";
import Q147O3 from "./Q147O3.png";
import Q147O4 from "./Q147O4.png";
import Q148 from "./Q148.png";
import Q148O1 from "./Q148O1.png";
import Q148O2 from "./Q148O2.png";
import Q148O3 from "./Q148O3.png";
import Q148O4 from "./Q148O4.png";
import Q149 from "./Q149.png";
import Q149O1 from "./Q149O1.png";
import Q149O2 from "./Q149O2.png";
import Q149O3 from "./Q149O3.png";
import Q149O4 from "./Q149O4.png";
import Q150 from "./Q150.png";
import Q150O1 from "./Q150O1.png";
import Q150O2 from "./Q150O2.png";
import Q150O3 from "./Q150O3.png";
import Q150O4 from "./Q150O4.png";
import Q151 from "./Q151.png";
import Q151O1 from "./Q151O1.png";
import Q151O2 from "./Q151O2.png";
import Q151O3 from "./Q151O3.png";
import Q151O4 from "./Q151O4.png";
import Q152 from "./Q152.png";
import Q152O1 from "./Q152O1.png";
import Q152O2 from "./Q152O2.png";
import Q152O3 from "./Q152O3.png";
import Q152O4 from "./Q152O4.png";
import Q153 from "./Q153.png";
import Q153O1 from "./Q153O1.png";
import Q153O2 from "./Q153O2.png";
import Q153O3 from "./Q153O3.png";
import Q153O4 from "./Q153O4.png";
import Q154 from "./Q154.png";
import Q154O1 from "./Q154O1.png";
import Q154O2 from "./Q154O2.png";
import Q154O3 from "./Q154O3.png";
import Q154O4 from "./Q154O4.png";
import Q155 from "./Q155.png";
import Q155O1 from "./Q155O1.png";
import Q155O2 from "./Q155O2.png";
import Q155O3 from "./Q155O3.png";
import Q155O4 from "./Q155O4.png";
import Q156 from "./Q156.png";
import Q156O1 from "./Q156O1.png";
import Q156O2 from "./Q156O2.png";
import Q156O3 from "./Q156O3.png";
import Q156O4 from "./Q156O4.png";
import Q157 from "./Q157.png";
import Q157O1 from "./Q157O1.png";
import Q157O2 from "./Q157O2.png";
import Q157O3 from "./Q157O3.png";
import Q157O4 from "./Q157O4.png";
import Q158 from "./Q158.png";
import Q158O1 from "./Q158O1.png";
import Q158O2 from "./Q158O2.png";
import Q158O3 from "./Q158O3.png";
import Q158O4 from "./Q158O4.png";
import Q159 from "./Q159.png";
import Q159O1 from "./Q159O1.png";
import Q159O2 from "./Q159O2.png";
import Q159O3 from "./Q159O3.png";
import Q159O4 from "./Q159O4.png";
import Q160 from "./Q160.png";
import Q160O1 from "./Q160O1.png";
import Q160O2 from "./Q160O2.png";
import Q160O3 from "./Q160O3.png";
import Q160O4 from "./Q160O4.png";
import Q161 from "./Q161.png";
import Q161O1 from "./Q161O1.png";
import Q161O2 from "./Q161O2.png";
import Q161O3 from "./Q161O3.png";
import Q161O4 from "./Q161O4.png";
import Q162 from "./Q162.png";
import Q162O1 from "./Q162O1.png";
import Q162O2 from "./Q162O2.png";
import Q162O3 from "./Q162O3.png";
import Q162O4 from "./Q162O4.png";
import Q163 from "./Q163.png";
import Q163O1 from "./Q163O1.png";
import Q163O2 from "./Q163O2.png";
import Q163O3 from "./Q163O3.png";
import Q163O4 from "./Q163O4.png";
import Q164 from "./Q164.png";
import Q164O1 from "./Q164O1.png";
import Q164O2 from "./Q164O2.png";
import Q164O3 from "./Q164O3.png";
import Q164O4 from "./Q164O4.png";
import Q165 from "./Q165.png";
import Q165O1 from "./Q165O1.png";
import Q165O2 from "./Q165O2.png";
import Q165O3 from "./Q165O3.png";
import Q165O4 from "./Q165O4.png";
import Q166 from "./Q166.png";
import Q166O1 from "./Q166O1.png";
import Q166O2 from "./Q166O2.png";
import Q166O3 from "./Q166O3.png";
import Q166O4 from "./Q166O4.png";
import Q167 from "./Q167.png";
import Q167O1 from "./Q167O1.png";
import Q167O2 from "./Q167O2.png";
import Q167O3 from "./Q167O3.png";
import Q167O4 from "./Q167O4.png";
import Q168 from "./Q168.png";
import Q168O1 from "./Q168O1.png";
import Q168O2 from "./Q168O2.png";
import Q168O3 from "./Q168O3.png";
import Q168O4 from "./Q168O4.png";
import Q169 from "./Q169.png";
import Q169O1 from "./Q169O1.png";
import Q169O2 from "./Q169O2.png";
import Q169O3 from "./Q169O3.png";
import Q169O4 from "./Q169O4.png";
import Q170 from "./Q170.png";
import Q170O1 from "./Q170O1.png";
import Q170O2 from "./Q170O2.png";
import Q170O3 from "./Q170O3.png";
import Q170O4 from "./Q170O4.png";
import Q171 from "./Q171.png";
import Q171O1 from "./Q171O1.png";
import Q171O2 from "./Q171O2.png";
import Q171O3 from "./Q171O3.png";
import Q171O4 from "./Q171O4.png";
import Q172 from "./Q172.png";
import Q172O1 from "./Q172O1.png";
import Q172O2 from "./Q172O2.png";
import Q172O3 from "./Q172O3.png";
import Q172O4 from "./Q172O4.png";
import Q173 from "./Q173.png";
import Q173O1 from "./Q173O1.png";
import Q173O2 from "./Q173O2.png";
import Q173O3 from "./Q173O3.png";
import Q173O4 from "./Q173O4.png";
import Q174 from "./Q174.png";
import Q174O1 from "./Q174O1.png";
import Q174O2 from "./Q174O2.png";
import Q174O3 from "./Q174O3.png";
import Q174O4 from "./Q174O4.png";
import Q175 from "./Q175.png";
import Q175O1 from "./Q175O1.png";
import Q175O2 from "./Q175O2.png";
import Q175O3 from "./Q175O3.png";
import Q175O4 from "./Q175O4.png";
import Q176 from "./Q176.png";
import Q176O1 from "./Q176O1.png";
import Q176O2 from "./Q176O2.png";
import Q176O3 from "./Q176O3.png";
import Q176O4 from "./Q176O4.png";
import Q177 from "./Q177.png";
import Q177O1 from "./Q177O1.png";
import Q177O2 from "./Q177O2.png";
import Q177O3 from "./Q177O3.png";
import Q177O4 from "./Q177O4.png";
import Q178 from "./Q178.png";
import Q178O1 from "./Q178O1.png";
import Q178O2 from "./Q178O2.png";
import Q178O3 from "./Q178O3.png";
import Q178O4 from "./Q178O4.png";
import Q179 from "./Q179.png";
import Q179O1 from "./Q179O1.png";
import Q179O2 from "./Q179O2.png";
import Q179O3 from "./Q179O3.png";
import Q179O4 from "./Q179O4.png";
import Q180 from "./Q180.png";
import Q180O1 from "./Q180O1.png";
import Q180O2 from "./Q180O2.png";
import Q180O3 from "./Q180O3.png";
import Q180O4 from "./Q180O4.png";
import Q181 from "./Q181.png";
import Q181O1 from "./Q181O1.png";
import Q181O2 from "./Q181O2.png";
import Q181O3 from "./Q181O3.png";
import Q181O4 from "./Q181O4.png";
import Q182 from "./Q182.png";
import Q182O1 from "./Q182O1.png";
import Q182O2 from "./Q182O2.png";
import Q182O3 from "./Q182O3.png";
import Q182O4 from "./Q182O4.png";
import Q183 from "./Q183.png";
import Q183O1 from "./Q183O1.png";
import Q183O2 from "./Q183O2.png";
import Q183O3 from "./Q183O3.png";
import Q183O4 from "./Q183O4.png";
import Q184 from "./Q184.png";
import Q184O1 from "./Q184O1.png";
import Q184O2 from "./Q184O2.png";
import Q184O3 from "./Q184O3.png";
import Q184O4 from "./Q184O4.png";
import Q185 from "./Q185.png";
import Q185O1 from "./Q185O1.png";
import Q185O2 from "./Q185O2.png";
import Q185O3 from "./Q185O3.png";
import Q185O4 from "./Q185O4.png";
import Q186 from "./Q186.png";
import Q186O1 from "./Q186O1.png";
import Q186O2 from "./Q186O2.png";
import Q186O3 from "./Q186O3.png";
import Q186O4 from "./Q186O4.png";
import Q187 from "./Q187.png";
import Q187O1 from "./Q187O1.png";
import Q187O2 from "./Q187O2.png";
import Q187O3 from "./Q187O3.png";
import Q187O4 from "./Q187O4.png";
import Q188 from "./Q188.png";
import Q188O1 from "./Q188O1.png";
import Q188O2 from "./Q188O2.png";
import Q188O3 from "./Q188O3.png";
import Q188O4 from "./Q188O4.png";
import Q189 from "./Q189.png";
import Q189O1 from "./Q189O1.png";
import Q189O2 from "./Q189O2.png";
import Q189O3 from "./Q189O3.png";
import Q189O4 from "./Q189O4.png";
import Q190 from "./Q190.png";
import Q190O1 from "./Q190O1.png";
import Q190O2 from "./Q190O2.png";
import Q190O3 from "./Q190O3.png";
import Q190O4 from "./Q190O4.png";

import obstacleTexture from "./obstacle.png";

export { obstacleTexture };
// Array(40)
//   .fill()
//   .map((el, i) => {
//     return `import Q${i + 151} from "./Q${i + 151}.png";
// import Q${i + 151}O1 from "./Q${i + 151}O1.png";
// import Q${i + 151}O2 from "./Q${i + 151}O2.png";
// import Q${i + 151}O3 from "./Q${i + 151}O3.png";
// import Q${i + 151}O4 from "./Q${i + 151}O4.png";
// `;
//   })
//   .join("");

const taskImages = {
  easy: [
    {
      image: L1Q1,
      answer: true,
    },
    {
      image: L1Q2,
      answer: true,
    },
    {
      image: L1Q3,
      answer: false,
    },
    {
      image: L1Q4,
      answer: false,
    },
    {
      image: L1Q5,
      answer: false,
    },
    {
      image: L1Q6,
      answer: true,
    },
    {
      image: L1Q7,
      answer: false,
    },
    {
      image: L1Q8,
      answer: true,
    },
    {
      image: L1Q9,
      answer: true,
    },
    {
      image: L1Q10,
      answer: false,
    },
    {
      image: L1Q11,
      answer: true,
    },
    {
      image: L1Q12,
      answer: false,
    },
    {
      image: L1Q13,
      answer: false,
    },
    {
      image: L1Q14,
      answer: true,
    },
    {
      image: L1Q15,
      answer: true,
    },
    {
      image: L1Q16,
      answer: true,
    },
    {
      image: L1Q17,
      answer: false,
    },
    {
      image: L1Q18,
      answer: true,
    },
    {
      image: L1Q19,
      answer: false,
    },
    {
      image: L1Q20,
      answer: true,
    },
    {
      image: L1Q21,
      answer: true,
    },
    {
      image: L1Q22,
      answer: false,
    },
    {
      image: L1Q23,
      answer: true,
    },
    {
      image: L1Q24,
      answer: false,
    },
    {
      image: L1Q25,
      answer: true,
    },
    {
      image: L1Q26,
      answer: false,
    },
    {
      image: L1Q27,
      answer: true,
    },
    {
      image: L1Q28,
      answer: true,
    },
    {
      image: L1Q29,
      answer: false,
    },
    {
      image: L1Q30,
      answer: false,
    },
    {
      image: L1Q31,
      answer: true,
    },
    {
      image: L1Q32,
      answer: false,
    },
    {
      image: L1Q33,
      answer: true,
    },
    {
      image: L1Q34,
      answer: true,
    },
    {
      image: L1Q35,
      answer: true,
    },
    {
      image: L1Q36,
      answer: false,
    },
    {
      image: L1Q37,
      answer: true,
    },
    {
      image: L1Q38,
      answer: true,
    },
    {
      image: L1Q39,
      answer: false,
    },
    {
      image: L1Q40,
      answer: false,
    },
    {
      image: L1Q41,
      answer: true,
    },
    {
      image: L1Q42,
      answer: false,
    },
    {
      image: L1Q43,
      answer: false,
    },
    {
      image: L1Q44,
      answer: true,
    },
    {
      image: L1Q45,
      answer: true,
    },
    {
      image: L1Q46,
      answer: true,
    },
    {
      image: L1Q47,
      answer: true,
    },
    {
      image: L1Q48,
      answer: false,
    },
    {
      image: L1Q49,
      answer: true,
    },
    {
      image: L1Q50,
      answer: false,
    },
    {
      image: L1Q51,
      answer: false,
    },
    {
      image: L1Q52,
      answer: true,
    },
    {
      image: L1Q53,
      answer: false,
    },
    {
      image: L1Q54,
      answer: true,
    },
    {
      image: L1Q55,
      answer: true,
    },
    {
      image: L1Q56,
      answer: true,
    },
    {
      image: L1Q57,
      answer: true,
    },
    {
      image: L1Q58,
      answer: true,
    },
    {
      image: L1Q59,
      answer: false,
    },
    {
      image: L1Q60,
      answer: false,
    },
    {
      image: L1Q61,
      answer: false,
    },
    {
      image: L1Q62,
      answer: false,
    },
    {
      image: L1Q63,
      answer: false,
    },
    {
      image: L1Q64,
      answer: false,
    },
    {
      image: L1Q65,
      answer: true,
    },
  ],
  medium: [
    {
      image: L2Q1,
      answer: true,
    },
    {
      image: L2Q2,
      answer: false,
    },
    {
      image: L2Q3,
      answer: false,
    },
    {
      image: L2Q4,
      answer: true,
    },
    {
      image: L2Q5,
      answer: false,
    },
    {
      image: L2Q6,
      answer: true,
    },
    {
      image: L2Q7,
      answer: false,
    },
    {
      image: L2Q8,
      answer: false,
    },
    {
      image: L2Q9,
      answer: true,
    },
    {
      image: L2Q10,
      answer: false,
    },
    {
      image: L2Q11,
      answer: true,
    },
    {
      image: L2Q12,
      answer: false,
    },
    {
      image: L2Q13,
      answer: false,
    },
    {
      image: L2Q14,
      answer: true,
    },
    {
      image: L2Q15,
      answer: false,
    },
    {
      image: L2Q16,
      answer: true,
    },
    {
      image: L2Q17,
      answer: false,
    },
    {
      image: L2Q18,
      answer: true,
    },
    {
      image: L2Q19,
      answer: false,
    },
    {
      image: L2Q20,
      answer: true,
    },
  ],
  hard: [
    {
      image: L3Q1,
      answer: true,
    },
    {
      image: L3Q2,
      answer: false,
    },
    {
      image: L3Q3,
      answer: true,
    },
    {
      image: L3Q4,
      answer: false,
    },
    {
      image: L3Q5,
      answer: true,
    },
    {
      image: L3Q6,
      answer: false,
    },
    {
      image: L3Q7,
      answer: false,
    },
    {
      image: L3Q8,
      answer: true,
    },
    {
      image: L3Q9,
      answer: true,
    },
    {
      image: L3Q10,
      answer: true,
    },
    {
      image: L3Q11,
      answer: false,
    },
    {
      image: L3Q12,
      answer: false,
    },
    {
      image: L3Q13,
      answer: false,
    },
    {
      image: L3Q14,
      answer: false,
    },
    {
      image: L3Q15,
      answer: false,
    },
  ],
};
export { taskImages };

let mcqImages = [
  {
    question: Q1,
    answer: {
      1: true,
      2: true,
    },
    options: [Q1O1, Q1O2, Q1O3, Q1O4],
  },
  {
    question: Q2,
    answer: {
      2: true,
      3: true,
    },
    options: [Q2O1, Q2O2, Q2O3, Q2O4],
  },
  {
    question: Q3,
    answer: {
      0: true,
      3: true,
    },
    options: [Q3O1, Q3O2, Q3O3, Q3O4],
  },
  {
    question: Q4,
    answer: {
      0: true,
      1: true,
    },
    options: [Q4O1, Q4O2, Q4O3, Q4O4],
  },
  {
    question: Q5,
    answer: {
      1: true,
      3: true,
    },
    options: [Q5O1, Q5O2, Q5O3, Q5O4],
  },
  {
    question: Q6,
    answer: {
      1: true,
      2: true,
    },
    options: [Q6O1, Q6O2, Q6O3, Q6O4],
  },
  {
    question: Q7,
    answer: {
      0: true,
      1: true,
    },
    options: [Q7O1, Q7O2, Q7O3, Q7O4],
  },
  {
    question: Q8,
    answer: {
      0: true,
      3: true,
    },
    options: [Q8O1, Q8O2, Q8O3, Q8O4],
  },
  {
    question: Q9,
    answer: {
      1: true,
      3: true,
    },
    options: [Q9O1, Q9O2, Q9O3, Q9O4],
  },
  {
    question: Q10,
    answer: {
      2: true,
      3: true,
    },
    options: [Q10O1, Q10O2, Q10O3, Q10O4],
  },
  {
    question: Q11,
    answer: {
      1: true,
      2: true,
    },
    options: [Q11O1, Q11O2, Q11O3, Q11O4],
  },
  {
    question: Q12,
    answer: {
      0: true,
      2: true,
    },
    options: [Q12O1, Q12O2, Q12O3, Q12O4],
  },
  {
    question: Q13,
    answer: {
      1: true,
      2: true,
    },
    options: [Q13O1, Q13O2, Q13O3, Q13O4],
  },
  {
    question: Q14,
    answer: {
      0: true,
      2: true,
    },
    options: [Q14O1, Q14O2, Q14O3, Q14O4],
  },
  {
    question: Q15,
    answer: {
      0: true,
      1: true,
    },
    options: [Q15O1, Q15O2, Q15O3, Q15O4],
  },
  {
    question: Q16,
    answer: {
      1: true,
      3: true,
    },
    options: [Q16O1, Q16O2, Q16O3, Q16O4],
  },
  {
    question: Q17,
    answer: {
      2: true,
      3: true,
    },
    options: [Q17O1, Q17O2, Q17O3, Q17O4],
  },
  {
    question: Q18,
    answer: {
      1: true,
      3: true,
    },
    options: [Q18O1, Q18O2, Q18O3, Q18O4],
  },
  {
    question: Q19,
    answer: {
      0: true,
      2: true,
    },
    options: [Q19O1, Q19O2, Q19O3, Q19O4],
  },
  {
    question: Q20,
    answer: {
      0: true,
      1: true,
    },
    options: [Q20O1, Q20O2, Q20O3, Q20O4],
  },
  {
    question: Q21,
    answer: {
      1: true,
      3: true,
    },
    options: [Q21O1, Q21O2, Q21O3, Q21O4],
  },
  {
    question: Q22,
    answer: {
      0: true,
      1: true,
    },
    options: [Q22O1, Q22O2, Q22O3, Q22O4],
  },
  {
    question: Q23,
    answer: {
      0: true,
      2: true,
    },
    options: [Q23O1, Q23O2, Q23O3, Q23O4],
  },
  {
    question: Q24,
    answer: {
      1: true,
      2: true,
    },
    options: [Q24O1, Q24O2, Q24O3, Q24O4],
  },
  {
    question: Q25,
    answer: {
      0: true,
      2: true,
    },
    options: [Q25O1, Q25O2, Q25O3, Q25O4],
  },
  {
    question: Q26,
    answer: {
      0: true,
      2: true,
    },
    options: [Q26O1, Q26O2, Q26O3, Q26O4],
  },
  {
    question: Q27,
    answer: {
      1: true,
      3: true,
    },
    options: [Q27O1, Q27O2, Q27O3, Q27O4],
  },
  {
    question: Q28,
    answer: {
      0: true,
      3: true,
    },
    options: [Q28O1, Q28O2, Q28O3, Q28O4],
  },
  {
    question: Q29,
    answer: {
      1: true,
      2: true,
    },
    options: [Q29O1, Q29O2, Q29O3, Q29O4],
  },
  {
    question: Q30,
    answer: {
      0: true,
      1: true,
    },
    options: [Q30O1, Q30O2, Q30O3, Q30O4],
  },
  {
    question: Q31,
    answer: {
      1: true,
      2: true,
    },
    options: [Q31O1, Q31O2, Q31O3, Q31O4],
  },
  {
    question: Q32,
    answer: {
      1: true,
      3: true,
    },
    options: [Q32O1, Q32O2, Q32O3, Q32O4],
  },
  {
    question: Q33,
    answer: {
      0: true,
      1: true,
    },
    options: [Q33O1, Q33O2, Q33O3, Q33O4],
  },
  {
    question: Q34,
    answer: {
      1: true,
      3: true,
    },
    options: [Q34O1, Q34O2, Q34O3, Q34O4],
  },
  {
    question: Q35,
    answer: {
      1: true,
      3: true,
    },
    options: [Q35O1, Q35O2, Q35O3, Q35O4],
  },
  {
    question: Q36,
    answer: {
      0: true,
      2: true,
    },
    options: [Q36O1, Q36O2, Q36O3, Q36O4],
  },
  {
    question: Q37,
    answer: {
      0: true,
      2: true,
    },
    options: [Q37O1, Q37O2, Q37O3, Q37O4],
  },
  {
    question: Q38,
    answer: {
      0: true,
      1: true,
    },
    options: [Q38O1, Q38O2, Q38O3, Q38O4],
  },
  {
    question: Q39,
    answer: {
      2: true,
      3: true,
    },
    options: [Q39O1, Q39O2, Q39O3, Q39O4],
  },
  {
    question: Q40,
    answer: {
      1: true,
      3: true,
    },
    options: [Q40O1, Q40O2, Q40O3, Q40O4],
  },
  {
    question: Q41,
    answer: {
      0: true,
      2: true,
    },
    options: [Q41O1, Q41O2, Q41O3, Q41O4],
  },
  {
    question: Q42,
    answer: {
      0: true,
      3: true,
    },
    options: [Q42O1, Q42O2, Q42O3, Q42O4],
  },
  {
    question: Q43,
    answer: {
      1: true,
      3: true,
    },
    options: [Q43O1, Q43O2, Q43O3, Q43O4],
  },
  {
    question: Q44,
    answer: {
      0: true,
      3: true,
    },
    options: [Q44O1, Q44O2, Q44O3, Q44O4],
  },
  {
    question: Q45,
    answer: {
      1: true,
      2: true,
    },
    options: [Q45O1, Q45O2, Q45O3, Q45O4],
  },
  {
    question: Q46,
    answer: {
      1: true,
      2: true,
    },
    options: [Q46O1, Q46O2, Q46O3, Q46O4],
  },
  {
    question: Q47,
    answer: {
      1: true,
      2: true,
    },
    options: [Q47O1, Q47O2, Q47O3, Q47O4],
  },
  {
    question: Q48,
    answer: {
      0: true,
      3: true,
    },
    options: [Q48O1, Q48O2, Q48O3, Q48O4],
  },
  {
    question: Q49,
    answer: {
      0: true,
      2: true,
    },
    options: [Q49O1, Q49O2, Q49O3, Q49O4],
  },
  {
    question: Q50,
    answer: {
      1: true,
      2: true,
    },
    options: [Q50O1, Q50O2, Q50O3, Q50O4],
  },
  {
    question: Q51,
    answer: {
      1: true,
      2: true,
    },
    options: [Q51O1, Q51O2, Q51O3, Q51O4],
  },
  {
    question: Q52,
    answer: {
      2: true,
      3: true,
    },
    options: [Q52O1, Q52O2, Q52O3, Q52O4],
  },
  {
    question: Q53,
    answer: {
      0: true,
      2: true,
    },
    options: [Q53O1, Q53O2, Q53O3, Q53O4],
  },
  {
    question: Q54,
    answer: {
      0: true,
      1: true,
    },
    options: [Q54O1, Q54O2, Q54O3, Q54O4],
  },
  {
    question: Q55,
    answer: {
      1: true,
      2: true,
    },
    options: [Q55O1, Q55O2, Q55O3, Q55O4],
  },
  {
    question: Q56,
    answer: {
      1: true,
      2: true,
    },
    options: [Q56O1, Q56O2, Q56O3, Q56O4],
  },
  {
    question: Q57,
    answer: {
      0: true,
      3: true,
    },
    options: [Q57O1, Q57O2, Q57O3, Q57O4],
  },
  {
    question: Q58,
    answer: {
      1: true,
      2: true,
    },
    options: [Q58O1, Q58O2, Q58O3, Q58O4],
  },
  {
    question: Q59,
    answer: {
      0: true,
      3: true,
    },
    options: [Q59O1, Q59O2, Q59O3, Q59O4],
  },
  {
    question: Q60,
    answer: {
      0: true,
      2: true,
    },
    options: [Q60O1, Q60O2, Q60O3, Q60O4],
  },
  {
    question: Q61,
    answer: {
      1: true,
      2: true,
    },
    options: [Q61O1, Q61O2, Q61O3, Q61O4],
  },
  {
    question: Q62,
    answer: {
      0: true,
      2: true,
    },
    options: [Q62O1, Q62O2, Q62O3, Q62O4],
  },
  {
    question: Q63,
    answer: {
      0: true,
      2: true,
    },
    options: [Q63O1, Q63O2, Q63O3, Q63O4],
  },
  {
    question: Q64,
    answer: {
      0: true,
      2: true,
    },
    options: [Q64O1, Q64O2, Q64O3, Q64O4],
  },
  {
    question: Q65,
    answer: {
      1: true,
      2: true,
    },
    options: [Q65O1, Q65O2, Q65O3, Q65O4],
  },
  {
    question: Q66,
    answer: {
      0: true,
      2: true,
    },
    options: [Q66O1, Q66O2, Q66O3, Q66O4],
  },
  {
    question: Q67,
    answer: {
      0: true,
      3: true,
    },
    options: [Q67O1, Q67O2, Q67O3, Q67O4],
  },
  {
    question: Q68,
    answer: {
      0: true,
      1: true,
    },
    options: [Q68O1, Q68O2, Q68O3, Q68O4],
  },
  {
    question: Q69,
    answer: {
      2: true,
      3: true,
    },
    options: [Q69O1, Q69O2, Q69O3, Q69O4],
  },
  {
    question: Q70,
    answer: {
      1: true,
      2: true,
    },
    options: [Q70O1, Q70O2, Q70O3, Q70O4],
  },
  {
    question: Q71,
    answer: {
      0: true,
      2: true,
    },
    options: [Q71O1, Q71O2, Q71O3, Q71O4],
  },
  {
    question: Q72,
    answer: {
      0: true,
      2: true,
    },
    options: [Q72O1, Q72O2, Q72O3, Q72O4],
  },
  {
    question: Q73,
    answer: {
      1: true,
      3: true,
    },
    options: [Q73O1, Q73O2, Q73O3, Q73O4],
  },
  {
    question: Q74,
    answer: {
      1: true,
      2: true,
    },
    options: [Q74O1, Q74O2, Q74O3, Q74O4],
  },
  {
    question: Q75,
    answer: {
      2: true,
      3: true,
    },
    options: [Q75O1, Q75O2, Q75O3, Q75O4],
  },
  {
    question: Q76,
    answer: {
      0: true,
      3: true,
    },
    options: [Q76O1, Q76O2, Q76O3, Q76O4],
  },
  {
    question: Q77,
    answer: {
      0: true,
      2: true,
    },
    options: [Q77O1, Q77O2, Q77O3, Q77O4],
  },
  {
    question: Q78,
    answer: {
      1: true,
      2: true,
    },
    options: [Q78O1, Q78O2, Q78O3, Q78O4],
  },
  {
    question: Q79,
    answer: {
      2: true,
      3: true,
    },
    options: [Q79O1, Q79O2, Q79O3, Q79O4],
  },
  {
    question: Q80,
    answer: {
      1: true,
      2: true,
    },
    options: [Q80O1, Q80O2, Q80O3, Q80O4],
  },
  {
    question: Q81,
    answer: {
      1: true,
      2: true,
    },
    options: [Q81O1, Q81O2, Q81O3, Q81O4],
  },
  {
    question: Q82,
    answer: {
      0: true,
      3: true,
    },
    options: [Q82O1, Q82O2, Q82O3, Q82O4],
  },
  {
    question: Q83,
    answer: {
      1: true,
      2: true,
    },
    options: [Q83O1, Q83O2, Q83O3, Q83O4],
  },
  {
    question: Q84,
    answer: {
      1: true,
      3: true,
    },
    options: [Q84O1, Q84O2, Q84O3, Q84O4],
  },
  {
    question: Q85,
    answer: {
      1: true,
      3: true,
    },
    options: [Q85O1, Q85O2, Q85O3, Q85O4],
  },
  {
    question: Q86,
    answer: {
      0: true,
      3: true,
    },
    options: [Q86O1, Q86O2, Q86O3, Q86O4],
  },
  {
    question: Q87,
    answer: {
      0: true,
      3: true,
    },
    options: [Q87O1, Q87O2, Q87O3, Q87O4],
  },
  {
    question: Q88,
    answer: {
      0: true,
      3: true,
    },
    options: [Q88O1, Q88O2, Q88O3, Q88O4],
  },
  {
    question: Q89,
    answer: {
      0: true,
      1: true,
    },
    options: [Q89O1, Q89O2, Q89O3, Q89O4],
  },
  {
    question: Q90,
    answer: {
      1: true,
      2: true,
    },
    options: [Q90O1, Q90O2, Q90O3, Q90O4],
  },
  {
    question: Q91,
    answer: {
      0: true,
      3: true,
    },
    options: [Q91O1, Q91O2, Q91O3, Q91O4],
  },
  {
    question: Q92,
    answer: {
      1: true,
      3: true,
    },
    options: [Q92O1, Q92O2, Q92O3, Q92O4],
  },
  {
    question: Q93,
    answer: {
      0: true,
      1: true,
    },
    options: [Q93O1, Q93O2, Q93O3, Q93O4],
  },
  {
    question: Q94,
    answer: {
      0: true,
      2: true,
    },
    options: [Q94O1, Q94O2, Q94O3, Q94O4],
  },
  {
    question: Q95,
    answer: {
      0: true,
      3: true,
    },
    options: [Q95O1, Q95O2, Q95O3, Q95O4],
  },
  {
    question: Q96,
    answer: {
      0: true,
      3: true,
    },
    options: [Q96O1, Q96O2, Q96O3, Q96O4],
  },
  {
    question: Q97,
    answer: {
      2: true,
      3: true,
    },
    options: [Q97O1, Q97O2, Q97O3, Q97O4],
  },
  {
    question: Q98,
    answer: {
      0: true,
      3: true,
    },
    options: [Q98O1, Q98O2, Q98O3, Q98O4],
  },
  {
    question: Q99,
    answer: {
      0: true,
      1: true,
    },
    options: [Q99O1, Q99O2, Q99O3, Q99O4],
  },
  {
    question: Q100,
    answer: {
      0: true,
      2: true,
    },
    options: [Q100O1, Q100O2, Q100O3, Q100O4],
  },
  {
    question: Q101,
    answer: {
      0: true,
      3: true,
    },
    options: [Q101O1, Q101O2, Q101O3, Q101O4],
  },
  {
    question: Q102,
    answer: {
      0: true,
      2: true,
    },
    options: [Q102O1, Q102O2, Q102O3, Q102O4],
  },
  {
    question: Q103,
    answer: {
      0: true,
      2: true,
    },
    options: [Q103O1, Q103O2, Q103O3, Q103O4],
  },
  {
    question: Q104,
    answer: {
      0: true,
      2: true,
    },
    options: [Q104O1, Q104O2, Q104O3, Q104O4],
  },
  {
    question: Q105,
    answer: {
      1: true,
      3: true,
    },
    options: [Q105O1, Q105O2, Q105O3, Q105O4],
  },
  {
    question: Q106,
    answer: {
      1: true,
      3: true,
    },
    options: [Q106O1, Q106O2, Q106O3, Q106O4],
  },
  {
    question: Q107,
    answer: {
      1: true,
      3: true,
    },
    options: [Q107O1, Q107O2, Q107O3, Q107O4],
  },
  {
    question: Q108,
    answer: {
      0: true,
      2: true,
    },
    options: [Q108O1, Q108O2, Q108O3, Q108O4],
  },
  {
    question: Q109,
    answer: {
      0: true,
      3: true,
    },
    options: [Q109O1, Q109O2, Q109O3, Q109O4],
  },
  {
    question: Q110,
    answer: {
      0: true,
      2: true,
    },
    options: [Q110O1, Q110O2, Q110O3, Q110O4],
  },
  {
    question: Q111,
    answer: {
      0: true,
      1: true,
    },
    options: [Q111O1, Q111O2, Q111O3, Q111O4],
  },
  {
    question: Q112,
    answer: {
      0: true,
      2: true,
    },
    options: [Q112O1, Q112O2, Q112O3, Q112O4],
  },
  {
    question: Q113,
    answer: {
      1: true,
      2: true,
    },
    options: [Q113O1, Q113O2, Q113O3, Q113O4],
  },
  {
    question: Q114,
    answer: {
      2: true,
      3: true,
    },
    options: [Q114O1, Q114O2, Q114O3, Q114O4],
  },
  {
    question: Q115,
    answer: {
      0: true,
      3: true,
    },
    options: [Q115O1, Q115O2, Q115O3, Q115O4],
  },
  {
    question: Q116,
    answer: {
      1: true,
      3: true,
    },
    options: [Q116O1, Q116O2, Q116O3, Q116O4],
  },
  {
    question: Q117,
    answer: {
      0: true,
      1: true,
    },
    options: [Q117O1, Q117O2, Q117O3, Q117O4],
  },
  {
    question: Q118,
    answer: {
      0: true,
      3: true,
    },
    options: [Q118O1, Q118O2, Q118O3, Q118O4],
  },
  {
    question: Q119,
    answer: {
      2: true,
      3: true,
    },
    options: [Q119O1, Q119O2, Q119O3, Q119O4],
  },
  {
    question: Q120,
    answer: {
      0: true,
      3: true,
    },
    options: [Q120O1, Q120O2, Q120O3, Q120O4],
  },
  {
    question: Q121,
    answer: {
      0: true,
      3: true,
    },
    options: [Q121O1, Q121O2, Q121O3, Q121O4],
  },
  {
    question: Q122,
    answer: {
      0: true,
      1: true,
    },
    options: [Q122O1, Q122O2, Q122O3, Q122O4],
  },
  {
    question: Q123,
    answer: {
      2: true,
      3: true,
    },
    options: [Q123O1, Q123O2, Q123O3, Q123O4],
  },
  {
    question: Q124,
    answer: {
      0: true,
      2: true,
    },
    options: [Q124O1, Q124O2, Q124O3, Q124O4],
  },
  {
    question: Q125,
    answer: {
      1: true,
      3: true,
    },
    options: [Q125O1, Q125O2, Q125O3, Q125O4],
  },
  {
    question: Q126,
    answer: {
      1: true,
      3: true,
    },
    options: [Q126O1, Q126O2, Q126O3, Q126O4],
  },
  {
    question: Q127,
    answer: {
      1: true,
      2: true,
    },
    options: [Q127O1, Q127O2, Q127O3, Q127O4],
  },
  {
    question: Q128,
    answer: {
      1: true,
      2: true,
    },
    options: [Q128O1, Q128O2, Q128O3, Q128O4],
  },
  {
    question: Q129,
    answer: {
      1: true,
      3: true,
    },
    options: [Q129O1, Q129O2, Q129O3, Q129O4],
  },
  {
    question: Q130,
    answer: {
      1: true,
      3: true,
    },
    options: [Q130O1, Q130O2, Q130O3, Q130O4],
  },
  {
    question: Q131,
    answer: {
      0: true,
      2: true,
    },
    options: [Q131O1, Q131O2, Q131O3, Q131O4],
  },
  {
    question: Q132,
    answer: {
      0: true,
      3: true,
    },
    options: [Q132O1, Q132O2, Q132O3, Q132O4],
  },
  {
    question: Q133,
    answer: {
      0: true,
      3: true,
    },
    options: [Q133O1, Q133O2, Q133O3, Q133O4],
  },
  {
    question: Q134,
    answer: {
      0: true,
      3: true,
    },
    options: [Q134O1, Q134O2, Q134O3, Q134O4],
  },
  {
    question: Q135,
    answer: {
      0: true,
      3: true,
    },
    options: [Q135O1, Q135O2, Q135O3, Q135O4],
  },
  {
    question: Q136,
    answer: {
      1: true,
      2: true,
    },
    options: [Q136O1, Q136O2, Q136O3, Q136O4],
  },
  {
    question: Q137,
    answer: {
      1: true,
      3: true,
    },
    options: [Q137O1, Q137O2, Q137O3, Q137O4],
  },
  {
    question: Q138,
    answer: {
      0: true,
      2: true,
    },
    options: [Q138O1, Q138O2, Q138O3, Q138O4],
  },
  {
    question: Q139,
    answer: {
      2: true,
      3: true,
    },
    options: [Q139O1, Q139O2, Q139O3, Q139O4],
  },
  {
    question: Q140,
    answer: {
      0: true,
      3: true,
    },
    options: [Q140O1, Q140O2, Q140O3, Q140O4],
  },
  {
    question: Q141,
    answer: {
      1: true,
      3: true,
    },
    options: [Q141O1, Q141O2, Q141O3, Q141O4],
  },
  {
    question: Q142,
    answer: {
      2: true,
      3: true,
    },
    options: [Q142O1, Q142O2, Q142O3, Q142O4],
  },
  {
    question: Q143,
    answer: {
      2: true,
      3: true,
    },
    options: [Q143O1, Q143O2, Q143O3, Q143O4],
  },
  {
    question: Q144,
    answer: {
      0: true,
      3: true,
    },
    options: [Q144O1, Q144O2, Q144O3, Q144O4],
  },
  {
    question: Q145,
    answer: {
      1: true,
      3: true,
    },
    options: [Q145O1, Q145O2, Q145O3, Q145O4],
  },
  {
    question: Q146,
    answer: {
      2: true,
      3: true,
    },
    options: [Q146O1, Q146O2, Q146O3, Q146O4],
  },
  {
    question: Q147,
    answer: {
      0: true,
      2: true,
    },
    options: [Q147O1, Q147O2, Q147O3, Q147O4],
  },
  {
    question: Q148,
    answer: {
      1: true,
      3: true,
    },
    options: [Q148O1, Q148O2, Q148O3, Q148O4],
  },
  {
    question: Q149,
    answer: {
      2: true,
      3: true,
    },
    options: [Q149O1, Q149O2, Q149O3, Q149O4],
  },
  {
    question: Q150,
    answer: {
      1: true,
      2: true,
    },
    options: [Q150O1, Q150O2, Q150O3, Q150O4],
  },
  {
    question: Q151,
    answer: {
      0: true,
      1: true,
    },
    options: [Q151O1, Q151O2, Q151O3, Q151O4],
  },
  {
    question: Q152,
    answer: {
      2: true,
      3: true,
    },
    options: [Q152O1, Q152O2, Q152O3, Q152O4],
  },
  {
    question: Q153,
    answer: {
      0: true,
      3: true,
    },
    options: [Q153O1, Q153O2, Q153O3, Q153O4],
  },
  {
    question: Q154,
    answer: {
      0: true,
      2: true,
    },
    options: [Q154O1, Q154O2, Q154O3, Q154O4],
  },
  {
    question: Q155,
    answer: {
      1: true,
      2: true,
    },
    options: [Q155O1, Q155O2, Q155O3, Q155O4],
  },
  {
    question: Q156,
    answer: {
      1: true,
      3: true,
    },
    options: [Q156O1, Q156O2, Q156O3, Q156O4],
  },
  {
    question: Q157,
    answer: {
      0: true,
      1: true,
    },
    options: [Q157O1, Q157O2, Q157O3, Q157O4],
  },
  {
    question: Q158,
    answer: {
      0: true,
      2: true,
    },
    options: [Q158O1, Q158O2, Q158O3, Q158O4],
  },
  {
    question: Q159,
    answer: {
      1: true,
      2: true,
    },
    options: [Q159O1, Q159O2, Q159O3, Q159O4],
  },
  {
    question: Q160,
    answer: {
      2: true,
      3: true,
    },
    options: [Q160O1, Q160O2, Q160O3, Q160O4],
  },
  {
    question: Q161,
    answer: {
      0: true,
      3: true,
    },
    options: [Q161O1, Q161O2, Q161O3, Q161O4],
  },
  {
    question: Q162,
    answer: {
      0: true,
      1: true,
    },
    options: [Q162O1, Q162O2, Q162O3, Q162O4],
  },
  {
    question: Q163,
    answer: {
      1: true,
      3: true,
    },
    options: [Q163O1, Q163O2, Q163O3, Q163O4],
  },
  {
    question: Q164,
    answer: {
      1: true,
      2: true,
    },
    options: [Q164O1, Q164O2, Q164O3, Q164O4],
  },
  {
    question: Q165,
    answer: {
      2: true,
      3: true,
    },
    options: [Q165O1, Q165O2, Q165O3, Q165O4],
  },
  {
    question: Q166,
    answer: {
      0: true,
      2: true,
    },
    options: [Q166O1, Q166O2, Q166O3, Q166O4],
  },
  {
    question: Q167,
    answer: {
      0: true,
      3: true,
    },
    options: [Q167O1, Q167O2, Q167O3, Q167O4],
  },
  {
    question: Q168,
    answer: {
      1: true,
      2: true,
    },
    options: [Q168O1, Q168O2, Q168O3, Q168O4],
  },
  {
    question: Q169,
    answer: {
      2: true,
      3: true,
    },
    options: [Q169O1, Q169O2, Q169O3, Q169O4],
  },
  {
    question: Q170,
    answer: {
      0: true,
      1: true,
    },
    options: [Q170O1, Q170O2, Q170O3, Q170O4],
  },
  {
    question: Q171,
    answer: {
      0: true,
      2: true,
    },
    options: [Q171O1, Q171O2, Q171O3, Q171O4],
  },
  {
    question: Q172,
    answer: {
      0: true,
      3: true,
    },
    options: [Q172O1, Q172O2, Q172O3, Q172O4],
  },
  {
    question: Q173,
    answer: {
      1: true,
      2: true,
    },
    options: [Q173O1, Q173O2, Q173O3, Q173O4],
  },
  {
    question: Q174,
    answer: {
      1: true,
      3: true,
    },
    options: [Q174O1, Q174O2, Q174O3, Q174O4],
  },
  {
    question: Q175,
    answer: {
      0: true,
      1: true,
    },
    options: [Q175O1, Q175O2, Q175O3, Q175O4],
  },
  {
    question: Q176,
    answer: {
      2: true,
      3: true,
    },
    options: [Q176O1, Q176O2, Q176O3, Q176O4],
  },
  {
    question: Q177,
    answer: {
      0: true,
      1: true,
    },
    options: [Q177O1, Q177O2, Q177O3, Q177O4],
  },
  {
    question: Q178,
    answer: {
      0: true,
      2: true,
    },
    options: [Q178O1, Q178O2, Q178O3, Q178O4],
  },
  {
    question: Q179,
    answer: {
      0: true,
      3: true,
    },
    options: [Q179O1, Q179O2, Q179O3, Q179O4],
  },
  {
    question: Q180,
    answer: {
      1: true,
      2: true,
    },
    options: [Q180O1, Q180O2, Q180O3, Q180O4],
  },
  {
    question: Q181,
    answer: {
      1: true,
      3: true,
    },
    options: [Q181O1, Q181O2, Q181O3, Q181O4],
  },
  {
    question: Q182,
    answer: {
      2: true,
      3: true,
    },
    options: [Q182O1, Q182O2, Q182O3, Q182O4],
  },
  {
    question: Q183,
    answer: {
      0: true,
      3: true,
    },
    options: [Q183O1, Q183O2, Q183O3, Q183O4],
  },
  {
    question: Q184,
    answer: {
      0: true,
      2: true,
    },
    options: [Q184O1, Q184O2, Q184O3, Q184O4],
  },
  {
    question: Q185,
    answer: {
      0: true,
      1: true,
    },
    options: [Q185O1, Q185O2, Q185O3, Q185O4],
  },
  {
    question: Q186,
    answer: {
      1: true,
      3: true,
    },
    options: [Q186O1, Q186O2, Q186O3, Q186O4],
  },
  {
    question: Q187,
    answer: {
      1: true,
      2: true,
    },
    options: [Q187O1, Q187O2, Q187O3, Q187O4],
  },
  {
    question: Q188,
    answer: {
      2: true,
      3: true,
    },
    options: [Q188O1, Q188O2, Q188O3, Q188O4],
  },
  {
    question: Q189,
    answer: {
      2: true,
      3: true,
    },
    options: [Q189O1, Q189O2, Q189O3, Q189O4],
  },
  {
    question: Q190,
    answer: {
      0: true,
      1: true,
    },
    options: [Q190O1, Q190O2, Q190O3, Q190O4],
  },
];
export { mcqImages };

export { googleLogo };
