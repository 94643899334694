import React from "react";
import { Link, useHistory } from "react-router-dom";
import {
  DotChallengeIcon,
  SymSudokuIcon,
  InductiveLogicalIcon,
  BrainMazeIcon,
} from "../assets/icons";
import firebase from "./../firebase";

function Home() {
  const locked = {
    grid: false,
    sudoku: false,
    mcq: false,
    motionChallenge: false,
  };
  const history = useHistory();
  return (
    <div className="min-h-screen w-screen flex flex-col  bg-black-222 gap-20 py-5">
      <div className="flex justify-end px-5">
        {/* <div className=''></div> */}
        <div>
          <button
            className="flex items-center justify-center px-5 rounded-md bg-black-111 text-white py-2 "
            onClick={() => {
              firebase.auth().signOut();
            }}
          >
            Sign out
          </button>
        </div>
      </div>
      <div className="flex flex-col justify-center h-full gap-20 items-center flex-grow">
        <div className="text-xl font-medium text-white max-w-xl text-center leading-none">
          Choose the game you want to play!
        </div>
        <div className="flex gap-5 flex-wrap justify-center px-3">
          <button
            className="p-5 bg-gray-F8 rounded-md font-medium text-center w-64 shadow-r2"
            onClick={() => {
              history.push("/grid2");
            }}
          >
            <div className="flex flex-col justify-center items-center gap-3">
              <img src={DotChallengeIcon} alt="icon" className="w-12 h-12" />
              <div className="text-2xl">Grid Challenge</div>
              <div className="text-sm">Mode 1</div>
            </div>
          </button>
          <button
            className="p-5 bg-gray-F8 rounded-md font-medium text-center w-64 shadow-r2"
            onClick={() => {
              history.push("/grid");
            }}
          >
            <div className="flex flex-col justify-center items-center gap-3">
              <img src={DotChallengeIcon} alt="icon" className="w-12 h-12" />
              <div className="text-2xl">Grid Challenge</div>
              <div className="text-sm">Mode 2</div>
            </div>
          </button>
          <button
            className={`p-5 bg-gray-F8 rounded-md font-medium text-center w-64 shadow-r2  ${
              locked.sudoku
                ? "cursor-not-allowed  border-red-600 border-2"
                : " "
            }`}
            onClick={() => {
              history.push("/sudoku");
            }}
            disabled={locked.sudoku}
          >
            <div className="flex flex-col justify-center items-center gap-3">
              <img src={SymSudokuIcon} alt="icon" className="w-12 h-12" />
              <div className="text-2xl">Deductive Logical Thinking</div>
              {locked.sudoku ? (
                <div className="text-red-600 text-xs leading-tight">
                  * Game is locked, it'll be available soon
                </div>
              ) : null}
            </div>
          </button>
          <button
            className={`p-5 bg-gray-F8 rounded-md font-medium text-center w-64 shadow-r2 ${
              locked.mcq ? "cursor-not-allowed  border-red-600 border-2" : " "
            }`}
            onClick={() => {
              history.push("/pattern");
            }}
            disabled={locked.mcq}
          >
            <div className="flex flex-col justify-center items-center gap-3">
              <img
                src={InductiveLogicalIcon}
                alt="icon"
                className="w-12 h-12"
              />
              <div className="text-2xl">Inductive Logical Thinking</div>
              {locked.mcq ? (
                <div className="text-red-600 text-xs leading-tight">
                  * Game is locked, it'll be available soon
                </div>
              ) : null}
            </div>
          </button>
          <button
            className={`p-5 bg-gray-F8 rounded-md font-medium text-center w-64 shadow-r2 ${
              locked.motionChallenge
                ? "cursor-not-allowed  border-red-600 border-2"
                : " "
            }`}
            onClick={() => {
              history.push("/motion");
            }}
            disabled={locked.motionChallenge}
          >
            <div className="flex flex-col justify-center items-center gap-3">
              <img src={BrainMazeIcon} alt="icon" className="w-12 h-12" />
              <div className="text-2xl">Motion Challenge</div>
              {locked.motionChallenge ? (
                <div className="text-red-600 text-xs leading-tight">
                  * Game is locked, it'll be available soon
                </div>
              ) : null}
            </div>
          </button>
        </div>
      </div>
    </div>
  );
}

export default Home;
