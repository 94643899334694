import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";
const firebaseConfig = {
  apiKey: "AIzaSyDWr5QaJYIdLFuf1JNyjx17nzALAHMlb0U",
  authDomain: "studyowl-games.firebaseapp.com",
  databaseURL: "https://studyowl-games.firebaseio.com",
  projectId: "studyowl-games",
  storageBucket: "studyowl-games.appspot.com",
  messagingSenderId: "846243801445",
  appId: "1:846243801445:web:613a58386a4f8fc417198c",
  measurementId: "G-WV53Z6N822",
};

const app = firebase.initializeApp(firebaseConfig);
/* Helper */

export const doCreateUserWithEmailAndPassword = (email, password) =>
  app.auth().createUserWithEmailAndPassword(email, password);

export const doSignInWithEmailAndPassword = (email, password) =>
  app.auth().signInWithEmailAndPassword(email, password);

export const doSignInWithGoogle = () =>
  app.auth().signInWithPopup(new firebase.auth.GoogleAuthProvider());

export const doSignInWithFacebook = () =>
  app.auth().signInWithPopup(new firebase.auth.FacebookAuthProvider());

export const doSignInWithTwitter = () =>
  app.auth().signInWithPopup(new firebase.auth.TwitterAuthProvider());

export const doSignOut = () => app.auth().signOut();

export const doPasswordReset = (email) =>
  app.auth().sendPasswordResetEmail(email);

export const doPasswordUpdate = (password) =>
  app.auth().currentUser.updatePassword(password);

// onAuthUserListener = (next, fallback) =>
//   this.auth.onAuthStateChanged((authUser) => {
//     if (authUser) {

//     } else {
//       fallback();
//     }
//   });

export default app;
