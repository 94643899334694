import { Form, Formik } from "formik";
import "rc-tooltip/assets/bootstrap.css";
import React, { useState } from "react";
import Countdown from "react-countdown";
import { BackIcon } from "../assets/icons";
import { mcqImages } from "../assets/images";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";

function MCQ() {
  const history = useHistory();
  const [endTime, setEndTime] = useState(Date.now() + 180000);
  const [gameStarted, setGameStarted] = useState(false);
  const [validaingAnswer, setValidatingAnswer] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [score, setScore] = useState(0);
  const [totalPlayed, setTotalPlayed] = useState(0);
  const [isCorrect, setIsCorrect] = useState(null);
  const startGame = () => {
    setScore(0);
    setTotalPlayed(0);
    setCurrentQuestion(0);
    mcqImages.sort(() => Math.random() - 0.5);
    setGameStarted(true);
    setEndTime(Date.now() + 360000);
  };
  // console.log(
  //   mcqImages[currentQuestion] ? mcqImages[currentQuestion].answer : null
  // );
  return (
    <main className="min-h-screen flex justify-center items-center cols h-full">
      <Formik
        enableReinitialize={true}
        initialValues={{
          question: mcqImages[currentQuestion],
          answer:
            currentQuestion < mcqImages.length
              ? {
                  ...mcqImages[currentQuestion].options.reduce(
                    (prev, cur, index) => {
                      return {
                        ...prev,
                        [index]: false,
                      };
                    },
                    {}
                  ),
                }
              : {},
        }}
        onSubmit={(values, actions) => {
          setValidatingAnswer(true);
          const correct = Object.keys(values.answer).reduce((prev, cur) => {
            if (!mcqImages[currentQuestion].answer[cur]) return prev;
            return (
              prev &&
              mcqImages[currentQuestion].answer[cur] === values.answer[cur]
            );
          }, true);
          setIsCorrect(correct);
          if (correct) setScore((score) => score + 1);

          setTotalPlayed((count) => count + 1);
          setTimeout(() => {
            setIsCorrect(null);
            setCurrentQuestion((i) => i + 1);
            setValidatingAnswer(false);
          }, 500);
        }}
      >
        {({ handleSubmit, values, errors, handleChange, setFieldValue }) => (
          <Form
            onSubmit={handleSubmit}
            className="w-128 min-h-screen bg-orange-500 flex flex-col items-center border h-full "
          >
            <div className="w-full py-3 flex justify-between px-5 bg-gray-F7">
              <div className="flex justify-center items-center">
                <button
                  className="mr-3 bg-gray-F8 rounded-md font-medium"
                  onClick={(e) => {
                    e.preventDefault();
                    history.push("/");
                  }}
                >
                  <img src={BackIcon} className="h-5 w-5" alt="go back" />
                </button>
                <div>Inductive Logical Thinking</div>
              </div>
              <Countdown
                date={endTime}
                onComplete={() => setCurrentQuestion(mcqImages.length)}
                precision={3}
                renderer={({
                  completed,
                  formatted: { hours, minutes, seconds },
                }) => {
                  return (
                    <div>
                      {gameStarted
                        ? currentQuestion === mcqImages.length
                          ? "00"
                          : minutes
                        : "06"}
                      :
                      {gameStarted
                        ? currentQuestion === mcqImages.length
                          ? "00"
                          : seconds
                        : "00"}
                    </div>
                  );
                }}
              />
            </div>
            <div className="flex flex-grow h-full justify-center items-center">
              {gameStarted ? (
                currentQuestion < mcqImages.length ? (
                  <div className="flex flex-col gap-3 justify-center py-3 h-full">
                    <div className="flex  justify-center items-center flex-col gap-3">
                      <div className="text-lg text-white">
                        These two grids follow a rule
                      </div>
                      <div>
                        <img
                          src={mcqImages[currentQuestion].question}
                          className="px-2 h-48 object-contain"
                          alt="question"
                        />
                      </div>
                    </div>
                    <div className="flex flex-col justify-center items-center gap-3">
                      <div className="text-lg text-white">
                        Which of these grids follow the same rule
                      </div>

                      <div className={`grid grid-cols-2 gap-5`}>
                        {mcqImages[currentQuestion].options.map(
                          (imgFile, i) => {
                            return (
                              <button
                                onClick={(e) => {
                                  e.preventDefault();
                                  if (values.answer[i])
                                    setFieldValue(`answer.${i}`, false);
                                  else if (
                                    Object.keys(values.answer).filter((el) => {
                                      return values.answer[el];
                                    }).length < 2
                                  )
                                    setFieldValue(`answer.${i}`, true);
                                }}
                                className="focus:outline-none"
                                disabled={validaingAnswer}
                              >
                                <img
                                  src={imgFile}
                                  className={`w-32 h-32 object-contain border-4 ${
                                    values.answer[i]
                                      ? "border-blue"
                                      : "border-black"
                                  }`}
                                  alt="options"
                                />
                              </button>
                            );
                          }
                        )}
                      </div>
                    </div>
                    <div className="flex justify-center items-center">
                      <button
                        className={`py-2 px-5 ${
                          isCorrect === null
                            ? "bg-gray-F8"
                            : isCorrect === true
                            ? "bg-green-600"
                            : "bg-red-600"
                        } rounded-md font-medium focus:outline-none`}
                        type="submit"
                        disabled={validaingAnswer}
                      >
                        {Object.keys(values.answer).filter((el) => {
                          return values.answer[el];
                        }).length === 2
                          ? "Next"
                          : "Skip"}
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-col justify-center items-center">
                    <div className="text-2xl text-white">Game Over</div>
                    <div className="text-lg text-white">{`Score: ${score} out of ${totalPlayed}`}</div>
                    <button
                      className="py-2 px-5 bg-gray-F8 rounded-md font-medium mt-3"
                      onClick={(e) => {
                        e.preventDefault();
                        history.push("/");
                      }}
                    >
                      Home
                    </button>
                  </div>
                )
              ) : (
                <div className="flex flex-col justify-center items-center gap-3 w-full">
                  <div>
                    <button
                      className="py-2 px-5 bg-gray-F8 rounded-md font-medium text-center"
                      onClick={() => {
                        swal({
                          text: `1. A image will be displayed with 2 objects, that follow same rule
2. You will be showed with 4 objects and you need to identify which 2 of them follow the same rule as question`,
                          title: "Instructions",
                        });
                      }}
                    >
                      Game Instructions
                    </button>
                  </div>
                  <hr className="w-full text-white border-white border" />
                  <button
                    className="py-2 px-5 bg-gray-F8 rounded-md font-medium"
                    onClick={startGame}
                  >
                    Start Game
                  </button>
                </div>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </main>
  );
}

export default MCQ;
