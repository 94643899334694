import React from "react";

function Dots({ x, y, highlighted, order, selected, size, index, clicked }) {
  return (
    <div
      className={`rounded-full absolute bg-gray-77 bg-opacity-75`}
      style={{
        width: `${size}px`,
        height: `${size}px`,
        top: y - size / 2,
        left: x - size / 2,
      }}
      onClick={(e) => {
        e.preventDefault();
        clicked(index);
        // console.log(index);
      }}
    >
      <div
        className={`w-full h-full rounded-full flex justify-center items-center text-white ${
          highlighted
            ? "bg-red-600 dot-scale-down"
            : selected
            ? "bg-blue-600"
            : "bg-transparent"
        }`}
      >
        {selected ? order : ""}
      </div>
    </div>
  );
}

export default Dots;

// cosnt Dots =  => {
//   constructor(x, y) {
//     this.x = x;
//     this.y = y;
//     this.fillColor = [255];
//     this.visited = false;
//     this.selected = false;
//     this.highlighted = false;
//     this.orderText = "";
//     dotCanvas.circle(x, y, dotSize);
//     this.size = 0;
//   }
//   return
//   <div>
//   </div>
// dotCanvas.fill(...this.fillColor);
// dotCanvas.strokeWeight(2);
// dotCanvas.stroke(255);
// if (this.highlighted) dotCanvas.fill(255);
// dotCanvas.circle(this.x, this.y, dotSize);
// if (this.highlighted) {
//   dotCanvas.strokeWeight(2);
//   dotCanvas.fill(...this.fillColor);
//   dotCanvas.stroke(...this.fillColor);
//   this.size++;
//   dotCanvas.circle(this.x, this.y, dotSize - this.size * 0.5);
//   if (this.size * 0.5 > dotSize * 0.3) {
//     this.size = 0;
//   }
// }
// dotCanvas.textAlign(CENTER, CENTER);
// dotCanvas.noStroke();
// dotCanvas.fill(255);
// dotCanvas.text(
//   this.orderText,
//   this.x - dotSize / 2,
//   this.y - dotSize / 2,
//   dotSize,
//   dotSize
// );

//   highlight() {
//     this.fillColor = [255, 0, 0];
//     this.highlighted = true;
//   }
//   select(order) {
//     this.fillColor = [0, 136, 255];
//     this.orderText = order;
//   }
//   unhighlight() {
//     this.fillColor = [255];
//     this.highlighted = false;
//     this.orderText = "";
//     this.size = 0;
//   }

//   unhighlightAfterDelay(delay) {
//     setTimeout(() => this.unhighlight(), delay);
//   }
//   checkIfInside(x, y) {
//     return dist(x, y, this.x, this.y) < dotSize;
//   }
// }
// export default Dots;
