import React, { useEffect, useRef, useState } from "react";
import Countdown from "react-countdown";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import {
  ArrowBack,
  ArrowDownward,
  ArrowForward,
  ArrowUpward,
  BackIcon,
} from "../../assets/icons";
import { obstacleTexture } from "../../assets/images";
import firebase from "../../firebase";

let gridSize = 60;
let blockSize = 50;
let gridCol = {
  3: "grid-cols-3",
  4: "grid-cols-4",
  5: "grid-cols-5",
};

const Block = ({ setSelected, name, block, selected, moveObjects }) => {
  const [background] = useState(
    `rgb(${Math.floor(Math.random() * 256)},${Math.floor(
      Math.random() * 256
    )},${Math.floor(Math.random() * 256)})`
  );
  return (
    <div
      onClick={() => {
        setSelected(name);
      }}
      id={name}
      className="rounded-md absolute cursor-pointer transition-all duration-500 ease-in-out"
      style={{
        width:
          block.dimention[1] * (gridSize + 4) - (gridSize - blockSize) * 0.75,
        height:
          block.dimention[0] * (gridSize + 4) - (gridSize - blockSize) * 0.75,
        top: block.position[0] * (gridSize + 4) + (gridSize - blockSize) * 0.25,
        left:
          block.position[1] * (gridSize + 4) + (gridSize - blockSize) * 0.25,
        background,
        ...(selected === name ? { zIndex: 1000 } : {}),
        opacity: 0.8,
      }}
    >
      {selected === name ? (
        <div className="relative w-full h-full">
          <div
            className="absolute flex justify-center items-center inset-x-0"
            style={{
              top: 0,
              zIndex: 1000,
            }}
            onClick={() => {
              moveObjects("U");
            }}
          >
            <div className=" rounded-md bg-black p-2 bg-opacity-50 text-white -translate-y-12 transform">
              <img src={ArrowUpward} alt="Up" className="w-6 h-6" />
            </div>
          </div>
          <div
            className="absolute flex justify-center items-center inset-y-0"
            style={{
              left: 0,
              zIndex: 1000,
            }}
            onClick={() => {
              moveObjects("L");
            }}
          >
            <div className=" rounded-md bg-black p-2 bg-opacity-50 text-white -translate-x-12 transform">
              <img src={ArrowBack} alt="Left" className="w-6 h-6" />
            </div>
          </div>
          <div
            className="absolute flex justify-center items-center inset-x-0"
            style={{
              zIndex: 1000,
              bottom: 0,
            }}
            onClick={() => {
              moveObjects("D");
            }}
          >
            <div className=" rounded-md bg-black p-2 bg-opacity-50 text-white translate-y-12 transform">
              <img src={ArrowDownward} alt="Down" className="w-6 h-6" />
            </div>
          </div>
          <div
            className="absolute flex justify-center items-center inset-y-0"
            style={{
              right: 0,
              zIndex: 1000,
            }}
            onClick={() => {
              moveObjects("R");
            }}
          >
            <div className=" rounded-md bg-black p-2 bg-opacity-50 text-white translate-x-12 transform">
              <img src={ArrowForward} alt="Right" className="w-6 h-6" />
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};
let currentIndex = 0;
let timeout;
function MotionChallenge() {
  const [startingPosition, setStartingPosition] = useState({});
  const [currentPosition, setCurrentPosition] = useState({ x: 0, y: 0 });
  const [selected, setSelected] = useState("");
  const [gameStarted, setGameStarted] = useState(null);
  const history = useHistory();
  const countdown = useRef();
  const [endTime, setEndTime] = useState(Date.now() + 0);
  const [gameOver, setGameOver] = useState(false);
  const [boardStates, setBoardStates] = useState([]);
  const [boardState, setBoardState] = useState(null);
  const [loading, setLoading] = useState(true);
  const [levelEnded, setLevelEnded] = useState(false);
  const [totalMoves, setTotalMoves] = useState(0);
  const [score, setScore] = useState(0);
  const [totalPlayed, setTotalPlayed] = useState(1);
  // const movePlayer = (direction) => {};
  // const moveBlock = (direction) => {};
  const changeMinimumMove = async (index, moves) => {
    firebase
      .database()
      .ref(`motionChallengeBoardStates/${index}/minMoves`)
      .set(moves)
      .catch((e) => console.log(e));
  };
  const goToNextLevel = () => {
    // console.log(countdown.current);
    currentIndex++;
    if (currentIndex === boardStates.length) {
      countdown.current.pause();
      setGameOver(true);
    } else {
      setTotalMoves(0);
      setTotalPlayed((count) => count + 1);
      setSelected("");
      setLevelEnded(false);
      setBoardState(boardStates[currentIndex]);
    }
  };
  const moveObjects = (direction) => {
    let blockIndex;
    if (selected.startsWith("block")) {
      blockIndex = parseInt(selected.split("_")[1]);
    }
    let grid = Array(boardState.gridDimention[0])
      .fill()
      .map((_, i) => {
        return Array(boardState.gridDimention[1]).fill(true);
      });
    if (boardState.obstacles)
      grid = boardState.obstacles.reduce((prev, cur) => {
        let n;
        for (
          let i = cur.position[0];
          i < cur.dimention[0] + cur.position[0];
          i++
        ) {
          for (
            let j = cur.position[1];
            j < cur.dimention[1] + cur.position[1];
            j++
          ) {
            prev[i][j] = false;
          }
        }

        return prev;
      }, grid);
    grid = [
      ...(selected.startsWith("block")
        ? boardState.blocks.filter((_, i) => {
            return i !== parseInt(selected.split("_")[1]);
          })
        : boardState.blocks),
    ].reduce((prev, cur) => {
      let n;
      for (
        let i = cur.position[0];
        i < cur.dimention[0] + cur.position[0];
        i++
      ) {
        for (
          let j = cur.position[1];
          j < cur.dimention[1] + cur.position[1];
          j++
        ) {
          prev[i][j] = false;
        }
      }

      return prev;
    }, grid);
    if (selected !== "player") {
      grid[boardState.ballPosition[0]][boardState.ballPosition[1]] = false;
    }
    let requiredIndex = [];
    if (selected) {
      let block;
      if (selected.startsWith("block")) {
        block = parseInt(selected.split("_")[1]);
        let currentBlock = boardState.blocks[block];

        Array(currentBlock.dimention[0])
          .fill()
          .forEach((_, i) => {
            Array(currentBlock.dimention[1])
              .fill()
              .forEach((_, j) => {
                requiredIndex.push({
                  i:
                    direction === "U" || direction === "D"
                      ? i +
                        currentBlock.position[0] +
                        (direction === "D" ? 1 : -1)
                      : i + currentBlock.position[0],
                  j:
                    direction === "L" || direction === "R"
                      ? j +
                        currentBlock.position[1] +
                        (direction === "R" ? 1 : -1)
                      : j + currentBlock.position[1],
                });
              });
          });
      } else if (selected === "player") {
        requiredIndex.push({
          i:
            direction === "U" || direction === "D"
              ? boardState.ballPosition[0] + (direction === "D" ? 1 : -1)
              : boardState.ballPosition[0],
          j:
            direction === "L" || direction === "R"
              ? boardState.ballPosition[1] + (direction === "R" ? 1 : -1)
              : boardState.ballPosition[1],
        });
      }
    }
    let isMovePossible = requiredIndex.reduce((prev, cur) => {
      return prev && grid[cur.i] && grid[cur.i][cur.j];
    }, true);
    if (isMovePossible) {
      const currentTotalMove = totalMoves;
      setTotalMoves(currentTotalMove + 1);
      setBoardState((boardState) => {
        return {
          ...boardState,
          ...(selected === "player"
            ? {
                ballPosition: [
                  direction === "U" || direction === "D"
                    ? boardState.ballPosition[0] + (direction === "D" ? 1 : -1)
                    : boardState.ballPosition[0],

                  direction === "L" || direction === "R"
                    ? boardState.ballPosition[1] + (direction === "R" ? 1 : -1)
                    : boardState.ballPosition[1],
                ],
              }
            : {}),
          ...(selected.startsWith("block")
            ? {
                blocks: boardState.blocks.map((block, i) => {
                  if (i === blockIndex) {
                    return {
                      ...block,
                      position: [
                        direction === "U" || direction === "D"
                          ? block.position[0] + (direction === "D" ? 1 : -1)
                          : block.position[0],

                        direction === "L" || direction === "R"
                          ? block.position[1] + (direction === "R" ? 1 : -1)
                          : block.position[1],
                      ],
                    };
                  } else {
                    return block;
                  }
                }),
              }
            : {}),
        };
      });
      if (
        selected === "player" &&
        (direction === "U" || direction === "D"
          ? boardState.ballPosition[0] + (direction === "D" ? 1 : -1)
          : boardState.ballPosition[0]) === boardState.holePosiiton[0] &&
        (direction === "L" || direction === "R"
          ? boardState.ballPosition[1] + (direction === "R" ? 1 : -1)
          : boardState.ballPosition[1]) === boardState.holePosiiton[1]
      ) {
        if (
          !boardState.minMoves ||
          boardState.minMoves > currentTotalMove + 1
        ) {
          changeMinimumMove(boardState.index, currentTotalMove + 1);
        }
        setScore((score) => score + 1);
        setLevelEnded(true);
        timeout = setTimeout(() => {
          goToNextLevel();
        }, 1100);
      }
    }
  };
  useEffect(() => {
    firebase
      .database()
      .ref("motionChallengeBoardStates")
      .once("value", (dataSnap) => {
        const val = dataSnap.val().map((el, i) => {
          return { ...el, index: i };
        });
        val.sort(() => Math.random() - 0.5);
        if (val) setBoardStates(val);
        setLoading(false);
      })
      .catch((e) => console.log(e));
    return () => {
      clearTimeout(timeout);
    };
  }, []);
  const startGame = () => {
    currentIndex = 0;
    setLevelEnded(false);
    setScore(0);
    setTotalPlayed(1);
    setSelected("");

    // boardStates.sort(() => Math.random() - 0.5);
    setEndTime(Date.now() + 360000);
    countdown.current.start();
    // console.log("countdown.current", countdown.current);
    // setGridData(currentModeData);
    // console.log("currentModeData", currentModeData);
    setBoardState(boardStates[0]);
    setGameStarted(true);
  };

  return (
    <div className="min-h-screen w-full bg-black-21 flex justify-center">
      <div className="flex flex-col w-128 relative items-center ">
        {/* <div className="flex gap-5">
          <div className="flex gap-5">{moves}</div>
          <div className="flex gap-5">{score}</div>
        </div> */}
        <div className="w-full py-3 flex justify-between px-5 bg-gray-F7">
          <div className="flex justify-center items-center">
            <button
              className="mr-3 bg-gray-F8 rounded-md font-medium"
              onClick={(e) => {
                e.preventDefault();
                history.push("/");
              }}
            >
              <img src={BackIcon} className="h-5 w-5" alt="go back" />
            </button>
            <div>Motion Challenge</div>
          </div>
          <Countdown
            date={endTime}
            ref={countdown}
            onComplete={() => setGameOver(true)}
            precision={3}
            renderer={({
              completed,
              formatted: { hours, minutes, seconds },
            }) => {
              return (
                <div>
                  {gameStarted ? (gameOver ? "00" : minutes) : "06"}:
                  {gameStarted ? (gameOver ? "00" : seconds) : "00"}
                </div>
              );
            }}
          />
        </div>
        {!loading ? (
          gameStarted ? (
            !gameOver ? (
              <div className="flex flex-col justify-center items-center w-full h-full bg-gray-77 relative gap-5">
                <div className="flex gap-5 text-white justify-between w-full px-5 pt-2 text-lg">
                  <div className="flex flex-col justify-center items-center">
                    <div>Moves</div>
                    <div>{totalMoves}</div>
                  </div>
                  <div className="flex flex-col justify-center items-center">
                    <div>Score</div>
                    <div>{score}</div>
                  </div>
                  <div className="flex flex-col justify-center items-center">
                    <div>Stage</div>
                    <div>{totalPlayed}</div>
                  </div>
                </div>
                <div
                  className="flex flex-col justify-center items-center w-full relative"
                  style={{
                    height: boardState.gridDimention[0] * (4 + gridSize),
                  }}
                >
                  <div
                    className={`absolute grid ${
                      gridCol[boardState.gridDimention[1]]
                    } justify-center items-center gap-1 border-gray-77`}
                    // style={{ height: `${9 * (85 + 2)}px` }}
                  >
                    {Array(boardState.gridDimention[0])
                      .fill()
                      .map((_, i) => {
                        return (
                          <>
                            {Array(boardState.gridDimention[1])
                              .fill()
                              .map((_, j) => {
                                return (
                                  <div
                                    id={`cell_${i}_${j}`}
                                    className="bg-white rounded-md"
                                    style={{
                                      height: gridSize,
                                      width: gridSize,
                                    }}
                                  ></div>
                                );
                              })}
                          </>
                        );
                      })}

                    <div className="absolute h-full w-full">
                      <div id="game-box" className="relative h-full w-full">
                        <div
                          id="player"
                          // draggable={true}

                          className="absolute transition-all duration-500 ease-in-out"
                          style={{
                            width: gridSize,
                            height: gridSize,
                            top: boardState.ballPosition[0] * (gridSize + 4),
                            left: boardState.ballPosition[1] * (gridSize + 4),
                            padding: (gridSize - blockSize) * 0.5,
                            zIndex: 1000,
                          }}
                        >
                          <div
                            onClick={() => {
                              setSelected("player");
                            }}
                            className={`rounded-full h-full w-full cursor-pointer ${
                              levelEnded ? "ball-disappear" : ""
                            }`}
                            style={{
                              background: `radial-gradient(circle at 65% 15%, white 1px, pink 3%, red 60%, pink 100%)`,
                            }}
                          >
                            {selected === `player` ? (
                              <div className="relative w-full h-full">
                                <div
                                  className="absolute flex justify-center items-center inset-x-0"
                                  style={{
                                    top: 0,
                                    zIndex: 1000,
                                  }}
                                  onClick={() => {
                                    moveObjects("U");
                                  }}
                                >
                                  <div className=" rounded-md bg-black p-2 bg-opacity-50 text-white -translate-y-12 transform">
                                    <img
                                      src={ArrowUpward}
                                      alt="Up"
                                      className="w-6 h-6"
                                    />
                                  </div>
                                </div>
                                <div
                                  className="absolute flex justify-center items-center inset-y-0"
                                  style={{
                                    left: 0,
                                    zIndex: 1000,
                                  }}
                                  onClick={() => {
                                    moveObjects("L");
                                  }}
                                >
                                  <div className=" rounded-md bg-black p-2 bg-opacity-50 text-white -translate-x-12 transform">
                                    <img
                                      src={ArrowBack}
                                      alt="Left"
                                      className="w-6 h-6"
                                    />
                                  </div>
                                </div>
                                <div
                                  className="absolute flex justify-center items-center inset-x-0"
                                  style={{
                                    bottom: 0,
                                    zIndex: 1000,
                                  }}
                                  onClick={() => {
                                    moveObjects("D");
                                  }}
                                >
                                  <div className=" rounded-md bg-black p-2 bg-opacity-50 text-white translate-y-12 transform">
                                    <img
                                      src={ArrowDownward}
                                      alt="Down"
                                      className="w-6 h-6"
                                    />
                                  </div>
                                </div>
                                <div
                                  className="absolute flex justify-center items-center inset-y-0"
                                  style={{
                                    right: 0,
                                    zIndex: 1000,
                                  }}
                                  onClick={() => {
                                    moveObjects("R");
                                  }}
                                >
                                  <div className=" rounded-md bg-black p-2 bg-opacity-50 text-white translate-x-12 transform">
                                    <img
                                      src={ArrowForward}
                                      alt="Right"
                                      className="w-6 h-6"
                                    />
                                  </div>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div
                          id="hole"
                          className="rounded-full bg-black absolute cursor-not-allowed"
                          style={{
                            width: blockSize,
                            height: blockSize,
                            top:
                              boardState.holePosiiton[0] * (gridSize + 4) +
                              (gridSize - blockSize) * 0.5,
                            left:
                              boardState.holePosiiton[1] * (gridSize + 4) +
                              (gridSize - blockSize) * 0.5,
                          }}
                        ></div>
                        {boardState.blocks.map((block, i) => {
                          return (
                            <Block
                              block={block}
                              moveObjects={moveObjects}
                              name={`block_${i}`}
                              selected={selected}
                              setSelected={setSelected}
                              key={`block_${i}`}
                            />
                          );
                        })}
                        {boardState.obstacles &&
                          boardState.obstacles.map((obstacle, i) => {
                            return (
                              <div
                                id={`obstacle_${i + 1}`}
                                className="rounded-md bg-black-333 absolute cursor-not-allowed bg-no-repeat object-fill "
                                style={{
                                  width:
                                    obstacle.dimention[1] * (gridSize + 4) -
                                    (gridSize - blockSize) * 0.75,
                                  height:
                                    obstacle.dimention[0] * (gridSize + 4) -
                                    (gridSize - blockSize) * 0.75,
                                  top:
                                    obstacle.position[0] * (gridSize + 4) +
                                    (gridSize - blockSize) * 0.25,
                                  left:
                                    obstacle.position[1] * (gridSize + 4) +
                                    (gridSize - blockSize) * 0.25,
                                  backgroundImage: `url(${obstacleTexture})`,
                                  backgroundSize: `100% 100%`,
                                }}
                              ></div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </div>
                {boardState.minMoves ? (
                  <div className="text-white">
                    Try to solve it in {boardState.minMoves} moves or less
                  </div>
                ) : null}
                <div className="flex justify-center">
                  <button
                    className="py-2 px-5 bg-gray-F8 rounded-md font-medium text-center"
                    onClick={() => {
                      goToNextLevel();
                    }}
                  >
                    Skip
                  </button>
                </div>
              </div>
            ) : (
              <div className="flex flex-col justify-center items-center h-full bg-gray-77 w-full">
                <div className="text-2xl text-white">Game Over</div>
                <div className="text-lg text-white">{`Score: ${score} out of ${totalPlayed}`}</div>
              </div>
            )
          ) : (
            <div className="flex flex-col gap-5 justify-center items-center h-full w-full bg-gray-77">
              <div>
                <button
                  className="py-2 px-5 bg-gray-F8 rounded-md font-medium text-center"
                  onClick={() => {
                    swal({
                      text: `In this game, you must find the path between two points in a maze - in the least number of moves possible.`,
                      title: "Instructions",
                    });
                  }}
                >
                  Game Instructions
                </button>
              </div>
              <hr className="w-full text-white" />
              <button
                className="py-2 px-5 bg-gray-F8 rounded-md font-medium text-center"
                onClick={() => {
                  startGame();
                }}
              >
                Start
              </button>
              {/* <div className="text-white text-xl">Select the mode</div>
            <div className="flex flex-col gap-5 justify-center items-center ">
              <button
                className="py-2 px-5 bg-gray-F8 rounded-md font-medium text-center"
                onClick={() => {
                  startGame();
                }}
              >
                Medium (5*5)
              </button>
              <button
                className="py-2 px-5 bg-gray-F8 rounded-md font-medium text-center"
                onClick={() => {
                  startGame();
                }}
              >
                Hard (Random)
              </button>
            </div> */}
            </div>
          )
        ) : (
          <div className="h-full w-full flex justify-center items-center bg-gray-77 text-lg text-white">
            Loading...
          </div>
        )}
      </div>
    </div>
  );
}

export default MotionChallenge;
