import FirebaseContext, { withFirebase } from "./context";
import firebase, {
  doCreateUserWithEmailAndPassword,
  doPasswordReset,
  doPasswordUpdate,
  doSignInWithEmailAndPassword,
  doSignInWithFacebook,
  doSignInWithGoogle,
  doSignInWithTwitter,
  doSignOut,
} from "./firebase";

export default firebase;

export {
  FirebaseContext,
  withFirebase,
  doCreateUserWithEmailAndPassword,
  doPasswordReset,
  doPasswordUpdate,
  doSignInWithEmailAndPassword,
  doSignInWithFacebook,
  doSignInWithGoogle,
  doSignInWithTwitter,
  doSignOut,
};
