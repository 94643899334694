import React, { useState, useEffect, useRef } from "react";
import Tooltip from "rc-tooltip";
import Countdown from "react-countdown";
import "rc-tooltip/assets/bootstrap.css";
import {
  CircleFilled,
  HeartFilled,
  PlusFilled,
  SquareFilled,
  StarFilled,
  TriangleFilled,
  BinOutline,
  QuestionFilled,
} from "../assets/icons";
import grid from "./sudokuBoardStateData.json";
import { BackIcon } from "../assets/icons";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
// console.log("grid", grid);

const options = {
  0: CircleFilled,
  1: TriangleFilled,
  2: PlusFilled,
  3: SquareFilled,
  4: StarFilled,
  5: HeartFilled,
  clear: BinOutline,
  question: QuestionFilled,
};
let gridType = "";
let currentModeData = [];
const OptionCell = ({ value, answer, validateAnswerAndShowNext, selected }) => {
  return (
    <button
      className={`w-12 h-12 sm:w-16 sm:h-16 ${
        selected !== value
          ? " bg-blue-200"
          : selected === answer
          ? "bg-green-600"
          : "bg-red-600"
      } flex justify-center items-center font-medium text-lg`}
      onClick={(e) => {
        e.preventDefault();
        validateAnswerAndShowNext(value);
      }}
      disabled={selected !== -1}
    >
      {value !== undefined && value !== null ? (
        <img src={options[value]} className={"w-6 h-6"} alt={"icon"} />
      ) : null}
      {/* <div className={hint ? "text-sm opacity-50" : ""}>
           {value ? value : hint}
        </div> */}
    </button>
  );
};
const Cell = ({ gridSize, value, question }) => {
  const [hint, setHint] = useState(null);
  useEffect(() => {
    setHint(null);
  }, [question]);
  return (
    <Tooltip
      placement="top"
      trigger="click"
      overlayClassName={"opacity-100"}
      overlay={
        <div className="flex gap-5">
          {Array(gridSize)
            .fill()
            .map((_, index) => {
              return (
                <button
                  className="p-2  bg-blue-200 text-black"
                  onClick={(e) => {
                    e.preventDefault();
                    setHint(index);
                  }}
                >
                  {
                    <img
                      src={options[index]}
                      className={"w-6 h-6"}
                      alt={index}
                    />
                  }
                </button>
              );
            })}

          <button
            className="p-2 text-black"
            onClick={(e) => {
              e.preventDefault();
              setHint(null);
            }}
          >
            {<img src={options["clear"]} className={"w-6 h-6"} alt={"clear"} />}
          </button>
        </div>
      }
    >
      <button
        className={`w-12 h-12 sm:w-16 sm:h-16  bg-blue-200 flex justify-center items-center font-medium text-lg ${
          !value ? "cursor-pointer" : "cursor-default"
        }`}
        onClick={(e) => {
          e.preventDefault();
        }}
        disabled={value !== undefined && value !== null ? true : false}
      >
        {(value !== undefined && value !== null) || hint !== null ? (
          <img
            src={options[value !== undefined && value !== null ? value : hint]}
            className={hint !== null ? "w-5 h-5 opacity-50" : "w-6 h-6"}
            alt={"icon"}
          />
        ) : null}
        {/* <div className={hint ? "text-sm opacity-50" : ""}>
           {value ? value : hint}
        </div> */}
      </button>
    </Tooltip>
  );
};

function Sudoku() {
  const history = useHistory();
  const [endTime, setEndTime] = useState(Date.now() + 0);
  // const [currentModeData, setGridData] = useState([]);
  const [gameStarted, setGameStarted] = useState(false);
  const [gameOver, setGameOver] = useState(false);
  const [selected, setSelected] = useState(-1);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [score, setScore] = useState(0);
  const [totalPlayed, setTotalPlayed] = useState(0);
  const countdown = useRef();
  const validateAnswerAndShowNext = async (ans) => {
    setSelected(ans);
    setTotalPlayed((count) => count + 1);
    if (ans === currentModeData[currentQuestion].answer) {
      setScore((score) => score + 1);
    }
    await setTimeout(() => {
      setSelected(-1);
      setCurrentQuestion((i) => i + 1);
    }, 1000);
  };
  const startGame = () => {
    setScore(0);
    setTotalPlayed(0);
    setSelected(-1);
    currentModeData.sort(() => Math.random() - 0.5);
    setEndTime(Date.now() + 360000);
    countdown.current.start();
    // console.log("countdown.current", countdown.current);
    // setGridData(currentModeData);
    // console.log("currentModeData", currentModeData);
    setCurrentQuestion(0);
    setGameStarted(true);
  };
  return (
    <main className="min-h-screen flex justify-center items-center cols">
      <div className="w-128 h-screen bg-orange-500 flex flex-col items-center border">
        <div className="w-full py-3 flex justify-between px-5 bg-gray-F7">
          <div className="flex justify-center items-center">
            <button
              className="mr-3 bg-gray-F8 rounded-md font-medium"
              onClick={(e) => {
                e.preventDefault();
                history.push("/");
              }}
            >
              <img src={BackIcon} className="h-5 w-5" alt="go back" />
            </button>
            <div>Deductive Logical Thinking</div>
          </div>
          <Countdown
            date={endTime}
            ref={countdown}
            onComplete={() => setGameOver(true)}
            precision={3}
            renderer={({
              completed,
              formatted: { hours, minutes, seconds },
            }) => {
              return (
                <div>
                  {gameStarted ? (gameOver ? "00" : minutes) : "06"}:
                  {gameStarted ? (gameOver ? "00" : seconds) : "00"}
                </div>
              );
            }}
          />
        </div>
        <div className="flex h-full justify-center items-center">
          {gameStarted ? (
            !gameOver ? (
              <div
                className={`grid ${
                  currentModeData[currentQuestion].gridSize === 3
                    ? " grid-cols-3"
                    : ""
                } ${
                  currentModeData[currentQuestion].gridSize === 4
                    ? " grid-cols-4"
                    : ""
                } ${
                  currentModeData[currentQuestion].gridSize === 5
                    ? " grid-cols-5"
                    : ""
                } ${
                  currentModeData[currentQuestion].gridSize === 6
                    ? " grid-cols-6"
                    : ""
                } gap-2 sm:gap-3`}
              >
                {Array(currentModeData[currentQuestion].gridSize)
                  .fill()
                  .map((_, i) => {
                    return Array(currentModeData[currentQuestion].gridSize)
                      .fill()
                      .map((_, j) => {
                        const cell =
                          currentModeData[currentQuestion].gridState[
                            i * currentModeData[currentQuestion].gridSize + j
                          ];
                        return (
                          <Cell
                            gridSize={currentModeData[currentQuestion].gridSize}
                            question={currentModeData[currentQuestion]}
                            value={
                              currentModeData[currentQuestion].question ===
                              i * currentModeData[currentQuestion].gridSize + j
                                ? "question"
                                : cell
                            }
                          />
                        );
                      });
                  })}
                <div
                  className={`grid ${
                    currentModeData[currentQuestion].gridSize === 3
                      ? "grid-cols-3 col-span-3"
                      : ""
                  } ${
                    currentModeData[currentQuestion].gridSize === 4
                      ? "grid-cols-4 col-span-4"
                      : ""
                  } ${
                    currentModeData[currentQuestion].gridSize === 5
                      ? "grid-cols-5 col-span-5"
                      : ""
                  } ${
                    currentModeData[currentQuestion].gridSize === 6
                      ? "grid-cols-6 col-span-6"
                      : ""
                  }  gap-3 mt-12`}
                >
                  {Array(currentModeData[currentQuestion].gridSize)
                    .fill()
                    .map((_, i) => {
                      return (
                        <OptionCell
                          value={i}
                          answer={currentModeData[currentQuestion].answer}
                          selected={selected}
                          validateAnswerAndShowNext={validateAnswerAndShowNext}
                        />
                      );
                    })}
                </div>
              </div>
            ) : (
              <div className="flex flex-col justify-center items-center">
                <div className="text-2xl text-white">Game Over</div>
                <div className="text-lg text-white">{`Score: ${score} out of ${totalPlayed}`}</div>
              </div>
            )
          ) : (
            <div className="flex flex-col gap-5 justify-center items-center h-full w-full">
              <div>
                <button
                  className="py-2 px-5 bg-gray-F8 rounded-md font-medium text-center"
                  onClick={() => {
                    swal({
                      text: `This is similar to SUDOKU game. Each row and each column will have unique symbols, and no symbol will repeat in a row or column. Each symbol will appear only once in a row and the respective column. Using this rule, find the symbol that fits into the block with Question Mark.`,
                      title: "Instructions",
                    });
                  }}
                >
                  Game Instructions
                </button>
              </div>
              <div className="text-white text-xl">Select the mode</div>
              <div className="flex flex-col gap-5 justify-center items-center ">
                <button
                  className="py-2 px-5 bg-gray-F8 rounded-md font-medium text-center"
                  onClick={() => {
                    currentModeData = grid["grid4"];
                    startGame();
                  }}
                >
                  Easy (4*4)
                </button>
                <button
                  className="py-2 px-5 bg-gray-F8 rounded-md font-medium text-center"
                  onClick={() => {
                    currentModeData = grid["grid5"];
                    startGame();
                  }}
                >
                  Medium (5*5)
                </button>
                <button
                  className="py-2 px-5 bg-gray-F8 rounded-md font-medium text-center"
                  onClick={() => {
                    currentModeData = Object.keys(grid).reduce((prev, cur) => {
                      return [...prev, ...grid[cur]];
                    }, []);
                    startGame();
                  }}
                >
                  Hard (Random)
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </main>
  );
}

export default Sudoku;
